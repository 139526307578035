import { action, makeAutoObservable } from 'mobx'

interface UserAgent {
  engine?: string
  browser?: string
  system?: string
  isMobile?: boolean
}

export class AppStateStore {
  rootStore
  authorization = ''
  shouldRunHandleVisibilityChange = true
  userAgent: UserAgent = {}
  docType: string = ''
  pageWidth: number = 0
  pageHeight: number = 0
  xs2aSessionKey: string = ''
  shouldAskForPhoneNumber: boolean = false
  shouldAskForEmail: boolean = false
  shouldSelectDocument: boolean = true
  showVideoIdHintsScreen: boolean = false
  shouldUploadBankStatement: boolean = false
  iframeContent: boolean = false
  showTransactionCompletedWaitingScreen: boolean = false
  documentUploadUrl: string = ''
  kontomatikSelectedBank: string = ''
  screenSize = {
    width: 0,
    height: 0,
  }

  constructor(rootStore) {
    makeAutoObservable(this)

    this.rootStore = rootStore
  }

  @action.bound setShouldAskForPhoneNumber(required: boolean): void {
    this.shouldAskForPhoneNumber = required
  }

  @action.bound setShouldAskForEmail(required: boolean): void {
    this.shouldAskForEmail = required
  }

  @action.bound setShouldSelectDocument(required: boolean): void {
    this.shouldSelectDocument = required
  }

  @action.bound setShowVideoIdHintsScreen(required: boolean): void {
    this.showVideoIdHintsScreen = required
  }

  @action.bound setXs2aSessionKey(key: string): void {
    this.xs2aSessionKey = key
  }

  @action.bound setAuthorization(auth: string): void {
    this.authorization = auth
  }

  @action.bound setUserAgent(userAgent: UserAgent): void {
    this.userAgent = userAgent
  }

  @action.bound setDocType(docType: string): void {
    this.docType = docType
  }

  @action.bound setPageWidth(width: number): void {
    this.pageWidth = width
  }

  @action.bound setPageHeight(height: number): void {
    this.pageHeight = height
  }

  @action.bound setScreenSize(screenSize: {
    width: number
    height: number
  }): void {
    this.screenSize = screenSize
  }

  @action.bound setIframeContent(enabled: boolean): void {
    this.iframeContent = enabled
  }

  @action.bound setShowTransactionCompletedWaitingScreen(show: boolean): void {
    this.showTransactionCompletedWaitingScreen = show
  }

  @action.bound setShouldUploadBankStatement(flag: boolean): void {
    this.shouldUploadBankStatement = flag
  }

  @action.bound setDocumentUploadUrl(url: string): void {
    this.documentUploadUrl = url
  }

  @action.bound setKontomatikSelectedBank(bank: string): void {
    this.kontomatikSelectedBank = bank
  }

  @action setShouldRunHandleVisibilityChange(shouldRun: boolean) {
    this.shouldRunHandleVisibilityChange = shouldRun
  }
}
