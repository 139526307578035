import { ContentContainer, LoadingSpinner } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { forceRedirect } from '../methods/forceRedirect'
import { devLog } from '../methods/devLog'

export const SaltedgeRedirectPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  const API_URL = process.env.WEB_API_URL

  const initSaltedge = async () => {
    try {
      const { data } = await axiosInstance.post<{ redirectUrl: string }>(
        `${API_URL}/salt-edge/start-session`,
        {},
        {
          withCredentials: true,
        }
      )

      forceRedirect(data.redirectUrl)
    } catch (error) {
      devLog('error: ', error)
    }
  }

  useEffect(() => {
    void initSaltedge()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </ContentContainer>
  )
})
