import { ContentContainer, Header } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'
import { HorizontalIconContainer } from '../styles/ikano.styles'

export const IncorrectPinCodePage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { SwitchToMobile: trans } = store.TranslationsState.translations

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header
        {...theme.header}
        fontFamily={theme.globals.fontFamilyHeadline}
        margin="30px 0 50px 0"
      >
        {trans.continueOnComputerPage.header}
      </Header>

      <HorizontalIconContainer>
        <svg
          width="168"
          height="314"
          viewBox="0 0 168 314"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="165.213"
            y="3"
            width="308"
            height="162.213"
            rx="20"
            transform="rotate(90 165.213 3)"
            fill="white"
            stroke="#DCDBE2"
            strokeWidth="5"
          />
          <path
            d="M5.49121 23C5.49121 13.0589 13.5501 5 23.4912 5H144.712C154.654 5 162.712 13.0589 162.712 23V34H5.49121V23Z"
            fill="#FCFBFD"
          />
          <rect
            x="60.4932"
            y="15.3198"
            width="47.2267"
            height="14.3733"
            rx="7.18667"
            fill="#DCDBE2"
          />
          <rect
            x="29"
            y="140"
            width="110"
            height="35"
            rx="1"
            stroke="#CED4DC"
            strokeWidth="2"
          />
          <path d="M44 148H48L58 167H54L44 148Z" fill="black" />
          <path d="M58 148H54L44 167H48L58 148Z" fill="black" />
          <path d="M66 148H70L80 167H76L66 148Z" fill="black" />
          <path d="M80 148H76L66 167H70L80 148Z" fill="black" />
          <path d="M88 148H92L102 167H98L88 148Z" fill="black" />
          <path d="M102 148H98L88 167H92L102 148Z" fill="black" />
          <path d="M110 148H114L124 167H120L110 148Z" fill="black" />
          <path d="M124 148H120L110 167H114L124 148Z" fill="black" />
          <rect
            x="117.646"
            y="120.354"
            width="3.54848"
            height="100.523"
            rx="1.77424"
            transform="rotate(45 117.646 120.354)"
            fill="#E32424"
          />
          <circle
            cx="84"
            cy="157"
            r="50.25"
            stroke="#E32424"
            strokeWidth="3.5"
          />
        </svg>
      </HorizontalIconContainer>
    </ContentContainer>
  )
})
