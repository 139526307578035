import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  Popup,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonText, IntroParagraph } from '../../styles/generic.styles'

import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MarginOnly,
  MobileSpacerForContentContainerBottomFixed,
  PageWrap,
} from '../../styles/layout.styles'

import { StoreContext } from '../App'
import { HorizontalIconContainer } from '../../styles/ikano.styles'
import { PermissionsErrorScreen } from './PermissionsErrorScreen'
import { DesktopInitIcon } from './icons/DesktopInitIcon'
import { MobileInitIcon } from './icons/MobileInitIcon'
import { LoadingWrapper, PopupWrapper } from './mediaPermissionsChecker.styles'
import { actionReport } from '../../methods/actionReport'

interface MediaPermissionsCheckerProps {
  onPermissionCheckEnd: (checked: boolean) => void
  redirectPath?: string
  redirectScenario?: string
}

const retryPaths = [
  '/video-id-retry',
  '/video-id-retry-ikano',
  '/video-id-retry-jitpay',
]

export const MediaPermissionsChecker = observer(
  (props: MediaPermissionsCheckerProps) => {
    const store = useContext(StoreContext)
    const { pageWidth, userAgent } = store.AppState
    const { theme } = store.InterfaceState
    const { currentScenarioId } = store.ScenarioState
    const { MediaPermissionsChecker: trans } =
      store.TranslationsState.translations
    const { browser, isMobile: isMobileAgent } = userAgent

    const path = window.location.pathname
    const isRetryScenario = retryPaths.includes(path)

    const [mobileVersion, setMobileVersion] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [error, setError] = useState('')
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [shouldShowPopup, setShouldShowPopup] = useState(true)

    const initialCheck = async () => {
      setIsLoading(true)
      try {
        const cameraPerm = await navigator.permissions.query({
          name: 'camera',
          //eslint-disable-next-line
        } as any)
        const microphonePerm = await navigator.permissions.query({
          name: 'microphone',
          //eslint-disable-next-line
        } as any)

        if (cameraPerm.state === 'denied') {
          actionReport({
            type: 'event.onboarding-web.process.CAMERA_PERMISSIONS_DENIED',
            payload: {},
          })
        }

        if (microphonePerm.state === 'denied') {
          actionReport({
            type: 'event.onboarding-web.process.MICROPHONE_PERMISSIONS_DENIED',
            payload: {},
          })
        }

        if (
          cameraPerm.state === 'granted' &&
          microphonePerm.state === 'granted'
        ) {
          props.onPermissionCheckEnd(true)
        }
        //eslint-disable-next-line
      } catch (error) {}
      setIsLoading(false)
    }

    const checkPermissions = async () => {
      try {
        // firefox don't support permissions API
        if (browser !== 'firefox') {
          const cameraPerm = await navigator.permissions.query({
            name: 'camera',
            //eslint-disable-next-line
          } as any)

          const microphonePerm = await navigator.permissions.query({
            name: 'microphone',
            //eslint-disable-next-line
          } as any)

          if (
            cameraPerm.state === 'granted' &&
            microphonePerm.state === 'granted'
          ) {
            props.onPermissionCheckEnd(true)
          }

          if (cameraPerm.state === 'denied') {
            actionReport({
              type: 'event.onboarding-web.process.CAMERA_PERMISSIONS_DENIED',
              payload: {},
            })
          }

          if (microphonePerm.state === 'denied') {
            actionReport({
              type: 'event.onboarding-web.process.MICROPHONE_PERMISSIONS_DENIED',
              payload: {},
            })
          }

          if (
            cameraPerm.state === 'denied' ||
            microphonePerm.state === 'denied'
          ) {
            setError('Error: Access denied')
          }

          requestAccess()
        } else {
          requestAccess()
        }
      } catch (error) {
        console.error('Error checking permissions:', error)
        setError('Error checking permissions')
      }
    }

    const requestAccess = async () => {
      setIsPopupVisible(true)

      try {
        // Request access to camera and microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        })

        //end stream
        stream.getTracks().forEach((track) => track.stop())

        props.onPermissionCheckEnd(true)
        // ... Use the media stream ...
      } catch (error) {
        console.error('Access denied:', error)
        setError('Error: Access denied')
      }
    }

    const startPermissionCheck = () => {
      startLoadingToDebouncePopup()
      void checkPermissions()
    }

    useEffect(() => {
      if (error) {
        setIsPopupVisible(false)
      }
    }, [error])

    useEffect(() => {
      if (isRetryScenario) {
        startPermissionCheck()
      } else if (browser !== 'firefox') {
        void initialCheck()
      }
    }, [])

    useEffect(() => {
      const isMobile = pageWidth && pageWidth <= 980

      if (isMobile || isMobileAgent || browser === 'safari') {
        setShouldShowPopup(false)
      }
    }, [browser, isMobileAgent])

    useEffect(() => {
      if (pageWidth && pageWidth <= 980) {
        setMobileVersion(true)
      }
    }, [pageWidth])

    const retryPermissionCheck = () => {
      const redirectScenario =
        props.redirectScenario || 'videoIdRetry' || 'video-id-retry'
      actionReport({
        type: 'event.onboarding-web.process.PERMISSIONS_RETRIES_NUMBER_INCREASED',
        payload: {
          retriesNumber: parseInt(localStorage.getItem('permRetries') || '-1'),
        },
      })
      const redirectPath = props.redirectPath || '/video-id-retry'

      if (currentScenarioId !== redirectScenario) {
        window.location.pathname = redirectPath
      } else {
        window.location.reload()
      }
    }

    const renderInitScreen = () =>
      isRetryScenario ? (
        <></>
      ) : (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
            margin="0 0 40px 0"
          >
            {trans.initHeader}
          </Header>

          <IntroParagraph
            color={theme.globals.linkColor}
            colorHover={theme.globals.linkColorHover}
            fontSize="20px"
            textAlign="center"
          >
            {trans.initDescription}
          </IntroParagraph>

          <MarginOnly margin="0 0 40px 0" />

          <HorizontalIconContainer>
            {mobileVersion ? <MobileInitIcon /> : <DesktopInitIcon />}
          </HorizontalIconContainer>

          <MobileSpacerForContentContainerBottomFixed />

          <ContentContainerBottomFixed>
            {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
            <Button
              id="btn-proceed"
              {...theme.button}
              minWidth="224px"
              paddingMobile="14px 40px"
              onClick={startPermissionCheck}
            >
              <ButtonText>{trans.proceed}</ButtonText>
            </Button>
          </ContentContainerBottomFixed>
        </>
      )

    const startLoadingToDebouncePopup = () => {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    }

    return (
      <ContentContainer
        {...theme.container}
        width="560px"
        paddingMobile="20px"
        marginMobile="0"
      >
        {isLoading || (isPopupVisible && !shouldShowPopup) ? (
          <LoadingWrapper>
            <LoadingSpinner
              width="80px"
              thickness={8}
              {...theme.loadingSpinner}
            />
          </LoadingWrapper>
        ) : (
          <>
            {!error ? (
              renderInitScreen()
            ) : (
              <PermissionsErrorScreen onRetry={retryPermissionCheck} />
            )}

            <PopupWrapper>
              <Popup
                visibility={isPopupVisible && shouldShowPopup}
                handleVisibility={setIsPopupVisible}
                {...theme.popup}
              >
                <PageWrap width="400px">
                  <HorizontalIconContainer>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="104"
                      height="104"
                      viewBox="0 0 104 104"
                      fill="none"
                    >
                      <circle
                        cx="52"
                        cy="52"
                        r="52"
                        fill={theme?.globals?.linkColor || 'gray'}
                      />
                      <path
                        d="M39.9246 40.0754L64.5656 64.7163M39.9246 40.0754V57.9246M39.9246 40.0754L57.7739 40.0754"
                        stroke="white"
                        strokeWidth="4"
                        strokeLinecap="round"
                      />
                    </svg>
                  </HorizontalIconContainer>

                  <Header
                    {...theme.header}
                    fontFamily={theme.globals.fontFamilyHeadline}
                    margin="15px 0 35px 0"
                  >
                    {trans.popupHeader}
                  </Header>

                  <IntroParagraph
                    color={theme.globals.linkColor}
                    colorHover={theme.globals.linkColorHover}
                    fontSize="14px"
                  >
                    {trans.popupDescription}
                  </IntroParagraph>

                  <MarginOnly margin="0 0 20px 0" />
                </PageWrap>
              </Popup>
            </PopupWrapper>
          </>
        )}
      </ContentContainer>
    )
  }
)
