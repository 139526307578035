import { Header } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../App'

import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const Error = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { ResumeFromEmailPage: trans } = store.TranslationsState.translations

  return (
    <>
      <Header
        {...theme.header}
        fontFamily={theme.globals.fontFamilyHeadline}
        margin="30px 0 50px 0"
      >
        {trans.OtpPage.error2}
      </Header>

      <HorizontalIconContainer>
        <svg
          width="168"
          height="314"
          viewBox="0 0 168 314"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="165.105"
            y="3"
            width="308"
            height="162.213"
            rx="20"
            transform="rotate(90 165.105 3)"
            fill="white"
            stroke="#DCDBE2"
            strokeWidth="5"
          />
          <path
            d="M5.38477 23C5.38477 13.0589 13.4436 5 23.3848 5H144.606C154.547 5 162.606 13.0589 162.606 23V34H5.38477V23Z"
            fill="#FCFBFD"
          />
          <rect
            x="60.3867"
            y="15.3198"
            width="47.2267"
            height="14.3733"
            rx="7.18667"
            fill="#DCDBE2"
          />
          <rect
            x="28.8926"
            y="140"
            width="110"
            height="35"
            rx="1"
            stroke="#CED4DC"
            strokeWidth="2"
          />
          <path
            d="M46.8709 148.516L50.3494 154.965L53.8279 148.516H58.1824L52.8123 157.682L58.3221 167H53.9295L50.3494 160.424L46.7693 167H42.3514L47.8738 157.682L42.491 148.516H46.8709ZM68.5748 148.516L72.0533 154.965L75.5318 148.516H79.8863L74.5162 157.682L80.026 167H75.6334L72.0533 160.424L68.4732 167H64.0553L69.5777 157.682L64.1949 148.516H68.5748ZM90.2787 148.516L93.7572 154.965L97.2357 148.516H101.59L96.2201 157.682L101.73 167H97.3373L93.7572 160.424L90.1772 167H85.7592L91.2816 157.682L85.8988 148.516H90.2787ZM111.983 148.516L115.461 154.965L118.94 148.516H123.294L117.924 157.682L123.434 167H119.041L115.461 160.424L111.881 167H107.463L112.986 157.682L107.603 148.516H111.983Z"
            fill="#34353A"
          />
          <rect
            x="117.539"
            y="120.354"
            width="3.54848"
            height="100.523"
            rx="1.77424"
            transform="rotate(45 117.539 120.354)"
            fill="#EC0000"
          />
          <circle
            cx="83.8926"
            cy="157"
            r="50.25"
            stroke="#EC0000"
            strokeWidth="3.5"
          />
        </svg>
      </HorizontalIconContainer>
    </>
  )
})
