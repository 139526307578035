import React, { useContext, useState } from 'react'
import axios from 'axios'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { Header, Button } from 'authflow-ui-engine'
import { IntroParagraph, ButtonText } from '../../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '../../../styles/layout.styles'
import { IntroIcon } from '../icons/IntroIcon'
import { devLog } from '../../../methods/devLog'

interface IntroProps {
  nextStep: () => void
  startMobileSessionUrl: string
}

export const Intro = observer(
  ({ nextStep, startMobileSessionUrl }: IntroProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { SwitchToMobile: trans } = store.TranslationsState.translations

    const [disabled, setDisabled] = useState(false)

    const startMobileSession = async () => {
      setDisabled(true)

      try {
        await axios.post(startMobileSessionUrl, {}, { withCredentials: true })
      } catch (error) {
        devLog(error)
      }

      nextStep()
      setDisabled(false)
    }

    return (
      <>
        <Header
          {...theme.header}
          fontFamily={theme.globals.fontFamilyHeadline}
          margin="30px 0"
        >
          {trans.pinIntro.header}
        </Header>

        <IntroParagraph>{trans.pinIntro.description}</IntroParagraph>

        <Margined margin="0 0 30px" />

        <IntroIcon />

        <ContentContainerBottomFixed border="none">
          <Button
            disabled={disabled}
            onClick={startMobileSession}
            {...theme.button}
            width="190px"
            paddingMobile="14px 10px"
          >
            <ButtonText>{trans.pinIntro.continue}</ButtonText>
          </Button>
        </ContentContainerBottomFixed>

        <MobileSpacerForContentContainerBottomFixed />
      </>
    )
  }
)
