import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
  Popup,
  Symbol,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../assets/privacy_policy_de.pdf'
import { StoreContext } from '../components/App'

import {
  ButtonImgWrap,
  ButtonText,
  Condition,
  JitpayIntro,
  OnboardingIcon,
  OnboardingItem,
  OnboardingList,
  OnboardingSingleLine,
} from '../styles/generic.styles'

import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../styles/layout.styles'

import { devLog } from '../methods/devLog'

export const KlarnaPrivacyPolicyPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState

  const { klarnaPrivacyPolicyPage: trans } =
    store.TranslationsState.translations
  const { language } = store.TranslationsState

  const [policyUrl, setPolicyUrl] = useState('')
  const [policy, setPolicy] = useState(false)
  const [loadState, setLoadState] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const [docPopupVisible, setDocPopupVisible] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
    }
  }, [pageWidth, pageHeight])

  const handleProceed = async () => {
    try {
      setBtnLoading(true)
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } catch (e) {
      devLog(e)
    }
  }

  const handlePolicy = ({ checked: value }) => {
    setPolicy(value)
  }

  useEffect(() => {
    if (!loadState) {
      setLoadState(true)
    }
  }, [])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  const isTouchScrollDisabled = () => {
    if (docPopupVisible || privPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      paddingMobile="60px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.privacyPolicyHeader}
      </Header>
      <JitpayIntro language={language}>
        <span>
          {trans.firstParagraphPartOne}
          <a
            href="https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42"
            target="_blank"
            rel="noreferrer"
          >
            {trans.serviceProvider}
          </a>
          {trans.firstParagraphPartTwo}
        </span>
      </JitpayIntro>
      <p>{trans.secondParagraph}</p>
      <OnboardingList>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol type="utility-bill" {...theme.symbol} size="58px" />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.wellLitPlace}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol type="globe" {...theme.symbol} size="58px" />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.stableConnection} </OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol type="camera" {...theme.symbol} size="58px" />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.cameraAccess} </OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol type="identity-card" {...theme.symbol} size="58px" />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.originalDocument}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol type="society-statement" {...theme.symbol} size="58px" />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.onlineBanking} </OnboardingSingleLine>
        </OnboardingItem>
      </OnboardingList>
      <p>{trans.thirdParagraph}</p>
      <p>{trans.fourthParagraph}</p>
      <div
        style={{
          borderTop: '1px solid rgb(80,80,80,0.1)',
          marginBottom: '40px',
        }}
      ></div>
      <Condition margin="20px 0 20px">
        <DocumentCheckbox
          inputName="terms-checkbox"
          description={trans.consent}
          externalUrl={policyUrl}
          handleDecision={handlePolicy}
          value={policy}
          {...theme.documentCheckbox}
          margin="0 0 30px 0"
          contentFontsize="14px"
          iconSize="26px"
          onClick={() => setDocPopupVisible(true)}
        />
      </Condition>
      <ContentContainerBottomFixed>
        <ContentContainerBottomLeft>
          {trans.required}
        </ContentContainerBottomLeft>
        <Button
          onClick={handleProceed}
          disabled={!policy}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
        >
          {btnLoading ? (
            <LoadingSpinner
              width="16px"
              {...theme.loadingSpinner}
              padding="0 0"
              thickness={2}
            />
          ) : (
            <>
              <ButtonImgWrap>
                <Icon size="18px" type="checkmark" />
              </ButtonImgWrap>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          )}
        </Button>
      </ContentContainerBottomFixed>

      <MobileSpacerForContentContainerBottomFixed />

      {mobileVersion ? (
        <>
          <Popup
            visibility={docPopupVisible}
            handleVisibility={setDocPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              canvasWidth={348}
              viewerWidth="100%"
              viewerHeight={containerPdfViewerHeight}
              initialScale={1}
              margin="30px 0 0 0"
              containerPadding="0px"
              navigationSpacerColor="none"
            />
          </Popup>
          <Popup
            visibility={privPopupVisible}
            handleVisibility={setPrivPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={policyUrl}
              {...theme.pdfViewer}
              canvasWidth={348}
              viewerWidth="100%"
              viewerHeight={containerPdfViewerHeight}
              initialScale={1}
              margin="30px 0 0 0"
              containerPadding="0px"
              navigationSpacerColor="none"
            />
          </Popup>
        </>
      ) : (
        <>
          <Popup
            visibility={docPopupVisible}
            handleVisibility={setDocPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              canvasWidth={728}
              viewerWidth="100%"
              viewerHeight={`calc(100vh - 405px)`}
              initialScale={1}
              margin="30px 0 0 0"
              containerPadding="0px"
              navigationSpacerColor="none"
            />
          </Popup>
          <Popup
            visibility={privPopupVisible}
            handleVisibility={setPrivPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              canvasWidth={728}
              viewerWidth="100%"
              viewerHeight={`calc(100vh - 405px)`}
              initialScale={1}
              margin="30px 0 0 0"
              containerPadding="0px"
              navigationSpacerColor="none"
            />
          </Popup>
        </>
      )}
    </ContentContainer>
  )
})
