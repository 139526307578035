import { Button, Header } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonText, IntroParagraph } from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MarginOnly,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'
import { StoreContext } from '../App'
import { AndroidPadlockErrorIcon } from './icons/AndroidPadlockErrorIcon'
import { AndroidSettingsErrorIcon } from './icons/AndroidSettingsErrorIcon'
import { AndroidShieldErrorIcon } from './icons/AndroidShieldErrorIcon'
import { DefaultErrorIcon } from './icons/DefaultErrorIcon'
import { DesktopPadlockErrorIcon } from './icons/DesktopPadlockErrorIcon'
import { DesktopSettingsErrorIcon } from './icons/DesktopSettingsErrorIcon'
import { ListWrapper, StepList } from './mediaPermissionsChecker.styles'
import { actionReport } from '../../methods/actionReport'

interface PermissionsErrorScreenProps {
  onRetry: () => void
}

export const PermissionsErrorScreen = observer(
  ({ onRetry }: PermissionsErrorScreenProps) => {
    const store = useContext(StoreContext)
    const { pageWidth, userAgent } = store.AppState
    const { theme } = store.InterfaceState

    const { MediaPermissionsChecker: trans } =
      store.TranslationsState.translations
    const { browser, system, isMobile: isMobileOS } = userAgent
    const [mobileVersion, setMobileVersion] = useState(false)

    const getBrowserStepList = () => {
      if (browser === 'unknown') {
        return trans.errorDescriptionListChrome
      }

      if (system === 'android') {
        switch (browser) {
          case 'chrome':
            return trans.errorDescriptionListAndroidChrome
          case 'firefox':
            return trans.errorDescriptionListAndroidFirefox
          case 'edge':
            return trans.errorDescriptionListAndroidEdge
          case 'opera':
            return trans.errorDescriptionListAndroidOpera
          case 'samsung':
            return trans.errorDescriptionListAndroidSamsung
          default:
            return trans.errorDescriptionListAndroidChrome
        }
      }

      if (!isMobileOS) {
        switch (browser) {
          case 'chrome':
            return trans.errorDescriptionListChrome
          case 'firefox':
            return trans.errorDescriptionListFirefox
          case 'edge':
            return trans.errorDescriptionListEdge
          case 'opera':
            return trans.errorDescriptionListOpera
          case 'safari':
            return trans.errorDescriptionListSafari
          default:
            return trans.errorDescriptionListChrome
        }
      }
    }

    const getErrorIcon = () => {
      if (system === 'android') {
        // android case
        switch (browser) {
          case 'chrome':
            return <AndroidSettingsErrorIcon />
          case 'firefox':
            return <AndroidPadlockErrorIcon />
          case 'opera':
            return <AndroidShieldErrorIcon />
          case 'edge':
            return <AndroidPadlockErrorIcon />
          case 'samsung':
            return <AndroidPadlockErrorIcon />
          default:
            return <DefaultErrorIcon />
        }
      } else if (!isMobileOS) {
        // desktop case
        switch (browser) {
          case 'chrome':
            return <DesktopSettingsErrorIcon />
          case 'firefox':
            return <DesktopSettingsErrorIcon />
          case 'opera':
            return <DesktopPadlockErrorIcon />
          case 'edge':
            return <DesktopPadlockErrorIcon />
          default:
            return <DefaultErrorIcon />
        }
      } else {
        // ios case
        return <DefaultErrorIcon />
      }
    }

    const renderTutorialSteps = () => {
      const supportedBrowsers = [
        'chrome',
        'firefox',
        'edge',
        'opera',
        'safari',
        'samsung',
      ]

      if (system === 'ios' || browser === 'safari') {
        return <div>{trans.errorDescriptionIOS}</div>
      } else if (supportedBrowsers.indexOf(browser) === -1) {
        return <div>{trans.errorDescriptionFallback}</div>
      } else {
        return (
          <ListWrapper fontSize="18px">
            {trans.errorDescription2} <br />
            <StepList>
              {getBrowserStepList().map((doctype) => (
                <li key={doctype}>{doctype}</li>
              ))}
            </StepList>
          </ListWrapper>
        )
      }
    }

    const renderPermissionDeniedScreen = () => {
      const permRetries = parseInt(localStorage.getItem('permRetries') || '0')
      localStorage.setItem('permRetries', (permRetries + 1).toString())

      if (permRetries < 2 && system === 'ios') {
        if (permRetries < 1) {
          onRetry()
        }

        return <></>
      } else if (permRetries < 1 && browser === 'safari') {
        onRetry()

        return <></>
      } else {
        return (
          <>
            <Header
              {...theme.header}
              fontFamily={theme.globals.fontFamilyHeadline}
              margin="0 0 40px 0"
            >
              {trans.errorHeader}
            </Header>

            <IntroParagraph
              color={theme.globals.linkColor}
              colorHover={theme.globals.linkColorHover}
              fontSize="20px"
              textAlign="center"
            >
              {trans.errorDescription1}
            </IntroParagraph>

            <MarginOnly margin="0 0 20px 0" />

            {getErrorIcon()}

            {renderTutorialSteps()}

            <MarginOnly margin="0 0 40px 0" />

            <MobileSpacerForContentContainerBottomFixed />

            <ContentContainerBottomFixed>
              {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
              <Button
                id="btn-proceed"
                {...theme.button}
                minWidth="224px"
                paddingMobile="14px 40px"
                onClick={() => {
                  actionReport({
                    type: 'event.onboarding-web.process.PERMISSIONS_RETRY_BUTTON_CLICKED',
                    payload: {},
                  })

                  onRetry()
                }}
              >
                <ButtonText>{trans.retry}</ButtonText>
              </Button>
            </ContentContainerBottomFixed>
          </>
        )
      }
    }

    useEffect(() => {
      if (pageWidth && pageWidth <= 980) {
        setMobileVersion(true)
      }
    }, [pageWidth])

    return <>{renderPermissionDeniedScreen()}</>
  }
)
