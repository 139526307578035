import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'

export type Language =
  | 'en'
  | 'enBmw'
  | 'da'
  | 'de'
  | 'deIkano' // de ikano
  | 'deBmw'
  | 'es'
  | 'bg'
  | 'cs'
  | 'el'
  | 'fi'
  | 'fr'
  | 'hu'
  | 'it'
  | 'itBmw'
  | 'nl'
  | 'no'
  | 'pl'
  | 'plBmw'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'sv'
  | 'svBmw'

type EidvTranslations = {
  errors: {
    'Image.Blurred': string
    'Authorization.Invalid': string
    'VideoID.Error': string
    'NFCChipNotDetected.Title': string
    'BWCopy.Detected': string
    'User.Aborted': string
    'DocumentSides.Mismatch': string
    'MaxAttemptsExceeded.Title': string
    'DeviceSecurityNotSatisfied.Title': string
    'Device.NotSupported': string
    'FacesMismatch.Detected': string
    'NFCReader.NotFound[Android]': string
    'VideoID.Rejected': string
    'Connection.Error': string
    'NFCValidationError.Title': string
    'Close.Message': string
    'RTCConnection.NotAvailable': string
    'CameraNotFound.Title': string
    'CameraUnavailable.Title': string
    'BrowserNotSupported.Title': string
    'Authorization.Reused': string
    'Barcode.NotDetected': string
    'User.Underage': string
    'MaxAttempts.Exceeded': string
    'CameraPermissionDenied.Items': string
    'MicrophonePermissionDenied.Items[iOS]': string
    'Camera.Unavailable': string
    'InactiveUser.Aborted': string
    'MicrophonePermission.Denied': string
    'MicrophonePermissionDenied.Actions': string
    'SmileID.NotValidated': string
    'IdType.Unauthorized': string
    'CameraPermissionDenied.Actions': string
    'BandwidthTooLow.Action': string
    'Connection.Lost': string
    'NFCReader.NotFound[iOS]': string
    'NoAgent.Available': string
    'DeviceSecurityNotSupported.Title': string
    'DocumentRotated.Title': string
    'Browser.NotSupported': string
    'DeviceSecurity.NotSupported': string
    'DocumentNotAllowed.Title': string
    'Default.Message': string
    'Recording.Error': string
    'LuminanceTooLow.Title': string
    'Close.Action': string
    'CameraPermissionDenied.Actions[iOS]': string
    'DocumentSidesMatch.NotPossible': string
    'CameraPermission.Denied': string
    'CameraRequirements.NotSatisfied': string
    'Https.Required': string
    'InstancesNotAvailable.Title': string
    'MaxLength.Exceeded': string
    'NFCAccessInvalid.Title': string
    'Document.Tampered': string
    'NFCAccess.Invalid': string
    'Luminance.TooHigh': string
    'IdType.Invalid': string
    'Microphone.NotFound': string
    'MicrophonePermissionDenied.Title': string
    'Instances.NotAvailable': string
    'MaxFps.Exceeded': string
    'Document.Glared': string
    'ConnectionLost.Title': string
    'Close.Message.Security': string
    'BandwidthTooLow.Title': string
    'MultipleFaces.Detected': string
    'MicrophonePermissionDenied.Actions[iOS]': string
    'Document.NotAllowed': string
    'CameraPermissionDenied.Items[iOS]': string
    'NFCReadingError.Title': string
    'Camera.NotFound': string
    'Agent.Aborted': string
    'Barcode.Illegible': string
    'DeviceSecurity.NotSatisfied': string
    'ConnectionError.Title': string
    'Bandwidth.TooLow': string
    'Document.Rotated': string
    'MicrophonePermissionDenied.Items': string
    'Hologram.NotDetected': string
    'Connection.Unstable': string
    'Default.Action': string
    'CameraPermissionDenied.Title': string
    'Captcha.Invalid': string
    'LuminanceTooHigh.Title': string
    'CameraUnavailable.Action': string
    'RTCConnection.Lost': string
    'DeviceSecurity.NotAvailable': string
    'FaceScanDiscontinuity.Detected': string
    'Document.Expired': string
    'Document.WrongSide': string
    'Surface.NotReflective': string
    'CameraRequirementsNotSatisfied.Title': string
    'NFCReader.NotFound': string
    'NFCReading.Error': string
    'DeviceSecurityNotAvailable.Title': string
    'MicrophoneNotFound.Title': string
    'CameraNotFound.Action': string
    'MicrophonePermissionDenied.Items[Android]': string
    'Document.Illegible': string
    'IdType.NotSupportedInWebBrowser': string
    'CameraPermissionDenied.Items[Android]': string
    'NFCReaderNotFound.Title': string
    'NFCValidation.Error': string
    'Luminance.TooLow': string
    'FaceSpoofing.Detected': string
    'RTCConnection.Error': string
    'NFCChip.NotDetected': string
    'Liveness.NotDetected': string
  }
  notifications: {
    'Pose.NotFacingFront': string
    'Hologram.Help': string
    'DocumentSelection.Action': string
    'FacesMismatch.Detected': string
    'HologramBackIntro.Title': string
    'UserInteraction.Request': string
    'Phone.Request': string
    'MaxFpsExceeded.Title': string
    'DocumentBackHelp.Action': string
    'VideoID.Finishing': string
    'TextCaptcha.Verification': string
    'Connection.Interrupted': string
    'DocumentBack.Intro': string
    'DocumentBack.Ok': string
    'OtpVerification.Actions': string
    'DocumentFront.TooSmall': string
    'NFCHelp.Title': string
    'User.WaitingAgent': string
    'Face.Intro': string
    'DocumentFrontTilt.Help': string
    'Barcode.NotDetected': string
    'HologramBackIntro.Subtitle': string
    'User.AmplifySmile': string
    'Phase.Repeat': string
    'NFC.Read[iOS]': string
    'SmileID.NotValidated': string
    'UserInactive.Title': string
    'BandwidthTooLow.Action': string
    'Passport.TooSmall': string
    'Action.WellDone': string
    'PhoneRequestRepeat.Notice': string
    'TriptychLicenseFrontHelp.Action': string
    'Connection.Establishing': string
    'DocumentNotAllowed.Title': string
    'UserConnection.Interrupted': string
    'Process.Completed': string
    'DocumentFront.Ok': string
    'BarcodeIllegible.Action': string
    'Document.OutOfFrame': string
    'AudioCaptchaVerification.Title': string
    'DocumentSelectionTriptych.Action': string
    'Document.Checking': string
    'DocumentBack.Show': string
    'BarcodeNotDetected.Title': string
    'ProcessRequirements.Action': string
    'DocumentFrontHelp.Action': string
    'PhoneRequest.Title': string
    'BandwidthTooLow.Title': string
    'DocumentBackTilt.Help': string
    'CameraPermissionRequest.Title': string
    'DocumentBackTiltHelp.Title': string
    'MultipleFaces.Detected': string
    'NFCHelp.Action': string
    'OtpCaptcha.Help': string
    'Connection.Established': string
    'HologramHelp.Action': string
    'Barcode.Illegible': string
    'NFC.Read': string
    'BiometricConsent.Action': string
    'Feedback.Actions': string
    'Bandwidth.TooLow': string
    'CameraPermission.Request': string
    'Hologram.NotDetected': string
    'DocumentFrontTiltHelp.Subtitle': string
    'LuminanceTooHigh.Title': string
    'ProcessRequirementsTriptychLicense.Title': string
    'NFC.Instructions': string
    'Feedback.Action': string
    'Feedback.Title': string
    'BarcodeNotDetected.Action': string
    'NFCReadingInterrupted.Title': string
    'FaceScanDiscontinuity.Detected': string
    'CertIDCompleted.Title': string
    'DocumentBack.Tilt': string
    'DocumentFront.Scanned': string
    'PhaseRepeat.Notice': string
    'AgentValidation.Ongoing': string
    'DocumentBackTriptych.Show': string
    'ProcessRequirements.Title': string
    'NFCRead.Items': string
    'DocumentBack.Scanned': string
    'Photo.NotDetected': string
    'NFCHelp.Items': string
    'Feedback.Request': string
    'Document.Tilt': string
    'Document.Illegible': string
    'Document.Selection': string
    'TextCaptchaHelp.Action': string
    'Face.Show': string
    'Luminance.TooLow': string
    'LuminanceTooHigh.Action': string
    'TextCaptchaVerification.Action': string
    'FaceSpoofing.Detected': string
    'OtpCaptchaHelp.Action': string
    'AgentConnection.Interrupted': string
    'Image.Blurred': string
    'Process.Requirements': string
    'Instructions.Title': string
    'NFCInstructions.Actions': string
    'PhoneRequest.Repeat': string
    'NFCChipNotDetected.Title': string
    'MaxFpsExceeded.Action': string
    'DocumentSides.Mismatch': string
    'CertID.Completed': string
    'DocumentSelectionTriptych.Title': string
    'Face.Ok': string
    'DocumentFrontTiltHelp.Title': string
    'PassportExtension.Help': string
    'CameraPermission.Notice': string
    'HologramBack.Intro': string
    'DocumentBackTiltHelp.Subtitle': string
    'Face.Help': string
    'ProcessRequirements.Items': string
    'CameraPermissionNotice.Action': string
    'DocumentIllegible.Action': string
    'Passport.Help': string
    'NFC.Instructions[Android]': string
    'PhaseRepeat.Actions': string
    'PassportExtensionHelp.Action': string
    'ProcessRequirementsTriptychLicense.Action': string
    'DocumentTampered.Action': string
    'Face.TooSmall': string
    'HologramBackIntro.Actions': string
    'UserInteractionRequest.Action': string
    'NFCReadingInterrupted.Items': string
    'DocumentIllegible.Title': string
    'PhoneRequest.Action': string
    'DocumentFront.Help': string
    'User.Underage': string
    'DocumentBack.Help': string
    'Connection.Reestablishing': string
    'AgentScanStart.Action': string
    'HologramFront.Intro': string
    'OtpVerification.Action': string
    'TriptychLicenseBackHelp.Action': string
    'HologramFrontIntro.Actions': string
    'AudioCaptchaVerification.Action': string
    'Face.Scanned': string
    'Otp.Verification': string
    'NFC.Help': string
    'LuminanceTooLow.Action': string
    'AudioCaptchaHelp.Action': string
    'Connection.Reestablished': string
    'Passport.Scanned': string
    'LuminanceTooLow.Title': string
    'AudioCaptcha.Help': string
    'Close.Action': string
    'NFCReader.Disabled': string
    'TriptychLicenseFront.Help': string
    'DocumentFrontTriptych.Show': string
    'ProcessCompleted.Title': string
    'Document.Tampered': string
    'OtpRequest.Message': string
    'Luminance.TooHigh': string
    'FaceHelp.Action': string
    'Instructions.Action': string
    'NFCReading.Interrupted': string
    'CameraPermissionRequest.Subtitle': string
    'FacesMismatchDetected.Action': string
    'MaxFps.Exceeded': string
    'PhoneRequestRepeat.Action': string
    'Document.Glared': string
    'BiometricConsent.Checkbox': string
    'FacesMismatchDetected.Title': string
    'NFCReadingInterrupted.Action': string
    'TextCaptcha.Help': string
    'FeedbackRequest.Actions': string
    'PassportExtension.Show': string
    'Document.NotAllowed': string
    'NFCInstructions.Title': string
    'Face.TooClose': string
    'NFC.Instructions[iOS]': string
    'AudioCaptcha.Verification': string
    'HologramFrontIntro.Title': string
    'Connection.Low': string
    'DocumentBack.TooSmall': string
    'Document.Rotated': string
    'DocumentFront.Show': string
    'DocumentFrontTiltHelp.Action': string
    'NFCReaderDisabled.Action': string
    'Captcha.Invalid': string
    'User.StartSmiling': string
    'NFCRead.Title': string
    'DocumentSelection.Title': string
    'User.StopSmiling': string
    'NFCReaderDisabled.Title': string
    'UserInactive.Actions': string
    'Document.Expired': string
    'ProcessRequirementsTriptychLicense.Items': string
    'Document.WrongSide': string
    'HologramFrontIntro.Subtitle': string
    'DocumentBackTiltHelp.Action': string
    'TriptychLicenseBack.Help': string
    'BarcodeIllegible.Title': string
    'Process.RequirementsTriptychLicense': string
    'Passport.Ok': string
    'VideoID.Repeat': string
    'DocumentNotAllowed.Actions': string
    'PassportHelp.Action': string
    'CameraPermissionRequest.Action': string
    'DocumentTampered.Title': string
    'Passport.Show': string
    'User.Inactive': string
    'DocumentFront.Tilt': string
    'NFCChip.NotDetected': string
  }
}

interface TranslationsInterface {
  pageTitle: string
  errorPage: {
    title: string
    description: string
    sessionExpiredTitle: string
    sessionExpiredDescription: string
    alreadyUsedTitle: string
    alreadyUsedDescription: string
  }
  bankSelectionPage: {
    header: string
    label: string
    proceed: string
  }
  documentTypePage: {
    header: string
    selectCountry: string
    proceed: string
    chooseDocument: string
    ResidentPermit: string
    EUResidentPermit: string
    TravelTitle: string
    DriverLicense: string
    Passport: string
    IdentityCard: string
    inputPlaceholder: string
  }
  generalConditionsPage: {
    header: string
    required: string
    proceed: string
    tickAll?: string
  }
  otpPage: {
    header: string
    headerVerifying: string
    verify: string
  }
  signDocumentPage: {
    header: string
    checkboxLabel: string
    proceed: string
  }
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: string
    firstParagraph: string
    firstParagraphPartOne: string
    serviceProvider: string
    firstParagraphPartTwo: string
    secondParagraph: string
    thirdParagraph: string
    fourthParagraph: string
    consent: string
    proceed: string
    required: string
    whatYouNeed: string
    wellLitPlace: string
    originalDocument: string
    stableConnection: string
    onlineBanking: string
    cameraAccess: string
  }
  userDataPage: {
    header: string
    email: string
    phone: string
    proceed: string
    inputLabel?: string
  }
  videoOnboardingPage: {
    header: string
    wellLitPlace: string
    originalDocument: string
    wifi: string
    start: string
  }
  progressPage: {
    heading: string
    wait: string
    waitMore: string
  }
  DTPInfoPage: {
    header: string
    content: string
    openBanking: string
    idData: string
    proceed: string
  }
  jitPayWelcomePage: {
    heading: string
    descriptionPartOne: string
    serviceProvider: string
    descriptionPartTwo: string
    idData: string
    proceed: string
    stepOne: string
    stepTwo: string
    stepThree: string
    stepThreeDesc: string
  }
  jitPayOnboardingPage: {
    heading: string
    signingHeader: string
    signingContent: string
    idVerifyHeader: string
    idVerifyContent: string
    micropaymentHeader: string
    micropaymentContent: string
    proceed: string
  }
  jitPayVideoOnboardingPage: {
    header: string
    lightHeading: string
    lightContent: string
    netHeading: string
    netContent: string
    deviceHeading: string
    deviceContent: string
    oneCameraHeading: string
    oneCameraContent: string
    proceed: string
  }
  micropaymentInfoPage: {
    content: string
    tink: string
    proceed: string
  }
  micropaymentInfoJitpayPage: {
    header: string
    content: string
    warning: string
    proceed: string
    idData: string
  }
  micropaymentInfoIkanoPage: {
    content: string
    tink: string
    proceed: string
  }
  micropaymentInfoIkanoOpenBankingPage: {
    content: string
    tink: string
    proceed: string
  }
  micropaymentInfoWithVideoIdIkanoPage: {
    content: string
    tink: string
    proceed: string
    header: string
    contentBPart1?: string
    contentBPart2?: string
    contentBPart3?: string
    contentBPart4?: string
    contentBPart5?: string
    contentBPart6?: string
    contentBPart7?: string
    contentBLogPart1?: string
    contentBLogPart2?: string
    yourName?: string
    continue: string
  }
  micropaymentReversedInfoWithVideoIdIkanoPage?: {
    content: string
    tink: string
    proceed: string
    header: string
    contentBPart1?: string
    contentBPart2?: string
    contentBPart3?: string
    contentBPart4?: string
    contentBPart5?: string
    contentBPart6?: string
    contentBPart7?: string
    contentBLogPart1?: string
    contentBLogPart2?: string
    yourName?: string
    continue: string
  }
  ikanoWelcomePage: {
    heading: string
    descriptionPartOne: string
    serviceProvider: string
    descriptionPartTwo: string
    proceed: string
    stepOne: string
    stepTwo: string
    stepThree: string
    stepThreeDesc: string
  }
  ikanoPolicies: {
    privacy: string
    terms: string
  }
  ikanoCompletePage: {
    submit: string
    message: string
  }
  signDocumentIkanoPage: {
    header: string
    checkboxLabel: string
    proceed: string
  }
  introKontomatikPage: {
    header: string
    intro: string
    accountInformation: string
    names: string
    address: string
    ownership: string
    verificationPurposes: string
    proceed: string
  }
  addressUploadPageKontomatik: {
    verifying: string
    header: string
    description: string
    confirmation: string
    bankStatementContains: string
    yourAddress: string
    proceed: string
    back?: string
    dragAndDrop: string
    chooseFile: string
    pko: {
      header: string
      confirmation: string
      whereToFindStatements: string
      selectPDF: string
      tutorial: {
        desktop: {
          version: string
          step1: string
          step2: string
          step3: string
          step4: string
          step5: string
        }
        mobile: {
          version: string
          step1: string
          step2: string
          step3: string
          step4: string
          step5: string
          step6: string
          step7: string
        }
      }
    }
    other: {
      header: string
      confirmation: string
      selectPDF: string
    }
  }
  pixelSpeak: {
    verifying: string
    header: string
    headerResidenceProofRetry?: string
    headerIdCardRetry?: string
    addressDescription?: string
    addressDescriptionResidenceProofRetry?: string
    addressDescriptionIdCardRetry?: string
    description: string
    descriptionResidenceProofRetry?: string
    descriptionIdCardRetry?: string
    documentsList: string[]
    documentTypes: Record<string, string>
    payslipDocumentTypes?: Record<string, string>
    proceed: string
    dragAndDrop: string
    dropHere: string
    chooseFile: string
    filetypes: string
    maxFilesize: string
    maxFilesizeValue: string
    fileErrorSize: string
    fileErrorType: string
    complete: string
  }
  addressVerificationIkano: {
    header: string
    description: string
    documentsList: string[]
    proceed: string
  }
  addressVerificationTwoSidedIkano: {
    header: string
    headerRetry?: string
    addressDescription?: string
    addressDescriptionRetry?: string
    descriptionPartOne: string
    descriptionPartOneRetry?: string
    documentsList: string[]
    descriptionPartTwo: string
    descriptionPartTwoRetry?: string
    proceed: string
  }
  AddressUploadFrontSideIkano: {
    verifying: string
    header: string
    description: string
    descriptionConfirm: string
    takePhoto: string
    retakePhoto: string
    proceed: string
    complete: string
    errorMessage: string
    errorRetry: string
  }
  AddressUploadBackSideIkano: {
    verifying: string
    header: string
    description: string
    descriptionConfirm: string
    takePhoto: string
    retakePhoto: string
    proceed: string
    complete: string
    errorMessage: string
    errorRetry: string
  }
  MediaPermissionsChecker: {
    initHeader: string
    initDescription: string
    proceed: string
    popupHeader: string
    popupDescription: string
    errorHeader: string
    errorDescription1: string
    errorDescription2: string
    errorDescriptionFallback: string
    errorDescriptionIOS: string
    errorDescriptionListChrome: string[]
    errorDescriptionListFirefox: string[]
    errorDescriptionListOpera: string[]
    errorDescriptionListEdge: string[]
    errorDescriptionListSafari: string[]
    errorDescriptionListAndroidChrome: string[]
    errorDescriptionListAndroidFirefox: string[]
    errorDescriptionListAndroidEdge: string[]
    errorDescriptionListAndroidOpera: string[]
    errorDescriptionListAndroidSamsung: string[]
    retry: string
  }
  VideoIdPage: {
    feedbackPopupTitle: string
    thankYouForYourFeedback: string
    abort: string
    send: string
    close: string
    retry: string
  }
  SwitchToMobile?: {
    scanQr: {
      header: string
      description1: string
      stay: string
      switch: string
      description2: string
      otherOptionsTitle: string
      sendSms: string
      sendSmsBtn: string
      sendSmsAgainBtn: string
      smsSent: string
      smsNotReceived: string
      sendSmsAgain: string
      stayOnThisDeviceQuestion: string
      stayOnThisDevice: string
      stayOnThisDeviceBtn: string
    }
    showPin: {
      header: string
      description: string
      spinner: string
      errorHeader: string
      errorDescription: string
      errorProceed: string
    }
    close: {
      header: string
      description: string
    }
    pinIntro: {
      header: string
      description: string
      continue: string
    }
    enterPin: {
      header: string
      description: string
      continue: string
      error1: string
      error2: string
      attemptsLeft: string
      lastAttempt: string
    }
    waitingForMobile: {
      header: string
      description: string
    }
    continueOnComputerPage: {
      header: string
      description: string
    }
    thankYouPage: {
      header: string
      description: string
    }
  }
  ResumeFromEmailPage?: {
    InfoPage?: {
      header: string
      description: string
      proceed: string
    }
    OtpPage?: {
      header: string
      description: string
      continue: string
      error1: string
      error2: string
      attemptsLeft: string
      lastAttempt: string
    }
  }
  BankIdRetryPage: {
    description: string
    retry: string
    cancel: string
  }
  eidv: EidvTranslations
}

function getFirstTwoCharacters(inputString) {
  if (inputString.length < 2) {
    return inputString
  }
  return inputString.substring(0, 2)
}

const findSubstringInString = (mainString, substrings) => {
  for (const substring of substrings) {
    if (mainString.includes(substring)) {
      return substring
    }
  }
  return ''
}

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

const customLanguageConfigurations = ['ikano', 'bmw']

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = translations.en
  language = 'en'
  paramLanguage = 'en'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTranslations(
    paramLanguage: Language,
    configurationId?: string
  ): void {
    this.language = getFirstTwoCharacters(paramLanguage)

    const lang = configurationId
      ? `${getFirstTwoCharacters(paramLanguage)}${capitalize(
          findSubstringInString(configurationId, customLanguageConfigurations)
        )}`
      : paramLanguage

    this.paramLanguage = lang
    this.translations = translations[lang]
      ? { ...translations.en, ...translations[lang] }
      : translations[paramLanguage]
  }
}
