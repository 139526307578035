import { ContentContainer, Header, ProgressBar } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import { forceRedirect } from '../methods/forceRedirect'
import { Subheading } from '../styles/generic.styles'
import { Margined } from '../styles/layout.styles'

export const ProgressPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { progressPage: trans } = store.TranslationsState.translations
  const [verified, setVerified] = useState(false)

  const handlePooling = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      const res = await axiosInstance.get(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/waiting`,
        {
          withCredentials: true,
        }
      )

      if (res.data) {
        const { isCompleted } = res.data
        if (isCompleted === true) {
          setVerified(true)
        }
      }
    } catch (e) {
      devLog(e)
    }
  }

  useInterval(
    () => {
      !verified && handlePooling()
    },
    !verified ? 3000 : null
  )

  useEffect(() => {
    if (verified) {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      forceRedirect(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/redirect-from-waiting`
      )
    }
  }, [verified])

  useEffect(() => {
    localStorage.setItem('show-waiting-screen', 'false')
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <Margined margin="0 0 15px">
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.heading}
        </Header>
      </Margined>
      <Subheading>{trans.wait}</Subheading>
      <ProgressBar
        {...theme.progressBar}
        time={180}
        secondChance={true}
        longerWait={trans.waitMore}
      />
    </ContentContainer>
  )
})
