import { Button, ContentContainer } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { StoreContext } from '../components/App'
import { BankIdLogo } from '../components/BankIdLogo'
import { forceRedirect } from '../methods/forceRedirect'
import { ButtonText, ParagraphCentered } from '../styles/generic.styles'
import { CenteredContent, MarginOnly } from '../styles/layout.styles'

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
  width: 100%;
`

const buttonStyles = {
  bgColor: '#2078C1',
  padding: '12px 36px',
  borderRadius: '4px',
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  margin: '0',
  color: 'rgba(255, 255, 255, 1)',
  colorHover: 'rgba(255, 255, 255, 1)',
  bgColorHover: 'rgba(16, 104, 165, 1)',
  borderHover: 'none',
  bgColorDisabled: 'rgba(0, 111, 207, 1)',
  borderDisabled: '1px solid rgba(0, 111, 207, 1)',
  colorDisabled: 'rgba(255, 255, 255, 1)',
  fontSize: '13px',
  fontWeight: '600',
}

const apiUrl = process.env.WEB_API_URL

export const BankIdRetryPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { BankIdRetryPage: trans } = store.TranslationsState.translations

  const handleRetry = () => {
    forceRedirect(`${apiUrl}/eid/retry?action=retry`)
  }

  const handleCancel = () => {
    forceRedirect(`${apiUrl}/eid/retry?action=cancel`)
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <CenteredContent>
        <BankIdLogo width={100} />
      </CenteredContent>
      <MarginOnly margin="10px" />
      <ParagraphCentered>{trans.description}</ParagraphCentered>

      <ButtonWrap>
        <Button {...buttonStyles} onClick={() => handleRetry()}>
          <ButtonText>{trans.retry}</ButtonText>
        </Button>

        <Button
          {...buttonStyles}
          bgColor="transparent"
          bgColorHover="transparent"
          color="#7b7578"
          colorHover="#9b9598"
          textDecoration="underline"
          onClick={() => handleCancel()}
        >
          <ButtonText>{trans.cancel}</ButtonText>
        </Button>
      </ButtonWrap>
    </ContentContainer>
  )
})
