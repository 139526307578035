import {
  Button,
  ContentContainer,
  Header,
  Icon,
  LoadingSpinner,
  TextInput,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import {
  ButtonImgWrap,
  ButtonText,
  InputContainer,
  Label,
} from '../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../styles/layout.styles'

export const UserDataPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex, currentScenarioId, context } = store.ScenarioState
  const { userDataPage: trans } = store.TranslationsState.translations

  const [isLoading, setIsLoading] = useState(true)

  const [emailInputValue, setEmailInputValue] = useState('')
  const [phoneInputValue, setPhoneInputValue] = useState('')
  const [validationErrorEmail, setValidationErrorEmail] = useState(false)
  const [validationErrorPhone, setValidationErrorPhone] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)

  const handleProceed = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (currentScenarioId !== 'localTests') {
      setBtnLoading(true)
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      let output: {
        email?: string
        phoneNumber?: string
      } = {}

      if (context.shouldAskForEmail) {
        output = {
          email: emailInputValue,
        }
      }

      if (context.shouldAskForPhoneNumber) {
        output = {
          phoneNumber: phoneInputValue.replace(/\s+/g, ''),
        }
      }

      if (context.shouldAskForEmail && context.shouldAskForPhoneNumber) {
        output = {
          email: emailInputValue,
          phoneNumber: phoneInputValue.replace(/\s+/g, ''),
        }
      }

      await axiosInstance.post(
        `${apiUrl}/electronic-id/update-contact-data`,
        output,
        {
          withCredentials: true,
        }
      )
      setBtnLoading(false)
    }

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  function getDisabledState() {
    let output = true

    if (context.shouldAskForEmail && context.shouldAskForPhoneNumber) {
      if (
        emailInputValue.length &&
        phoneInputValue.length &&
        !validationErrorEmail &&
        !validationErrorPhone
      ) {
        output = false
      }
    } else if (context.shouldAskForEmail && !context.shouldAskForPhoneNumber) {
      if (emailInputValue.length && !validationErrorEmail) {
        output = false
      }
    } else if (context.shouldAskForPhoneNumber && !context.shouldAskForEmail) {
      if (phoneInputValue.length && !validationErrorPhone) {
        output = false
      }
    }

    return output
  }

  useEffect(() => {
    if (!context.shouldAskForEmail && !context.shouldAskForPhoneNumber) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } else {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {!isLoading ? (
        <form onSubmit={(e) => handleProceed(e)}>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>
          <div>
            {context.shouldAskForEmail ? (
              <InputContainer>
                <Label>{trans.email}</Label>
                <TextInput
                  type="email"
                  value={emailInputValue}
                  onChange={(value) => {
                    setEmailInputValue(value)
                  }}
                  {...theme.textInput}
                  onError={(e) => setValidationErrorEmail(e)}
                  id="email-input"
                />
              </InputContainer>
            ) : (
              <></>
            )}
            {context.shouldAskForPhoneNumber ? (
              <InputContainer>
                <Label>{trans.phone}</Label>
                <TextInput
                  type="phone"
                  value={phoneInputValue}
                  onChange={(value) => {
                    setPhoneInputValue(value)
                  }}
                  {...theme.textInput}
                  onError={(e) => setValidationErrorPhone(e)}
                  id="phone-input"
                />
              </InputContainer>
            ) : (
              <></>
            )}
          </div>

          <ContentContainerBottomFixed>
            {!mobileVersion ? <ContentContainerBottomLeft /> : <div />}
            <Button
              onClick={(e) => handleProceed(e)}
              disabled={getDisabledState()}
              {...theme.button}
              width="164px"
              paddingMobile="14px 40px"
              id="page-submit"
              type="submit"
            >
              {btnLoading ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <>
                  <ButtonImgWrap>
                    <Icon size="18px" type="checkmark" />
                  </ButtonImgWrap>
                  <ButtonText>{trans.proceed}</ButtonText>
                </>
              )}
            </Button>
          </ContentContainerBottomFixed>

          <MobileSpacerForContentContainerBottomFixed />
        </form>
      ) : (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )}
    </ContentContainer>
  )
})
