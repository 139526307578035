import {
  Button,
  ContentContainer,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../../components/App'
import { axiosInstance } from '../../methods/axiosConfig'

import { actionReport } from '../../methods/actionReport'
import { devLog } from '../../methods/devLog'
import {
  ButtonImgWrap,
  ButtonText,
  CheckboxForm,
} from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentToRight,
} from '../../styles/layout.styles'
import { getViewerHeight } from '../../styles/utils'

export const SignDocumentIkanoPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth, screenSize } = store.AppState
  const { currentPageIndex, currentScenarioId } = store.ScenarioState
  const { theme } = store.InterfaceState
  const { signDocumentIkanoPage: trans } = store.TranslationsState.translations

  const [signed, setSigned] = useState(false)
  const [docUrl, setDocUrl] = useState('')
  const [loadState, setLoadState] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerHeight, setContainerHeight] = useState('')
  const headerRef = useRef(null)
  const footerRef = useRef(null)

  useEffect(() => {
    if (screenSize.width && screenSize.width <= 980) {
      setMobileVersion(true)
      setContainerHeight(
        `calc(${window.innerHeight}px - ${footerRef.current.offsetHeight}px)`
      )
    }
  }, [screenSize])

  const getDocument = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/pdf-document-presigned`,
        {
          withCredentials: true,
        }
      )

      setDocUrl(res.data.signedUrl)
      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_ACKNOWLEDGED',
        payload: {},
      })
    } catch (e) {
      devLog(e)
    }
  }

  const handleProceed = async () => {
    try {
      if (currentScenarioId !== 'localTests') {
        setBtnLoading(true)
        const apiUrl = process.env.WEB_API_URL

        await axiosInstance.post(
          `${apiUrl}/electronic-id/acceptance-agreement`,
          {},
          { withCredentials: true }
        )

        setBtnLoading(false)
      }

      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    if (!loadState) {
      setLoadState(true)
    }
  }, [])

  function simulateClick(e) {
    e.click()
  }

  useEffect(() => {
    if (loadState) {
      getDocument()
    }
  }, [loadState])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    if (signed === true) {
      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_CONFIRMED',
        payload: {},
      })
    }
  }, [signed])

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      minHeight="250px"
      paddingMobile="20px"
      heightMobile={containerHeight}
      marginMobile="0px"
      ref={simulateClick}
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        <span ref={headerRef}>{trans.header}</span>
      </Header>
      {docUrl && docUrl.length ? (
        mobileVersion ? (
          <PdfViewer
            file={docUrl}
            {...theme.pdfViewer}
            canvasWidth={348}
            viewerWidth="100%"
            containerHeight={getViewerHeight(
              screenSize,
              headerRef.current.offsetHeight,
              footerRef.current.offsetHeight
            )}
            viewerHeight="100%"
            initialScale={1}
            margin="30px 0 0 0"
            containerPadding="0px"
            navigationSpacerColor="none"
          />
        ) : (
          <PdfViewer
            file={docUrl}
            {...theme.pdfViewer}
            canvasWidth={865}
            viewerWidth="100%"
            viewerHeight={getViewerHeight(screenSize)}
            initialScale={1}
            navigationSpacerColor="none"
          />
        )
      ) : (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )}
      <ContentContainerBottomFixed ref={footerRef}>
        <CheckboxForm>
          <input
            type="checkbox"
            name="sign"
            value={signed ? 'true' : 'false'}
            id="sign"
            onChange={() => setSigned(!signed)}
          />
          <label className="label-text" htmlFor="sign">
            {trans.checkboxLabel}
          </label>
        </CheckboxForm>
        <ContentToRight>
          <Button
            onClick={handleProceed}
            {...theme.button}
            disabled={!signed || docUrl.length === 0}
            minWidth="224px"
            paddingMobile="14px 40px"
            id="page-submit"
          >
            {btnLoading ? (
              <LoadingSpinner
                width="16px"
                {...theme.loadingSpinner}
                padding="0 0"
                thickness={2}
              />
            ) : (
              <>
                <ButtonImgWrap>
                  <Icon type="pencil" size="18px" />
                </ButtonImgWrap>
                <ButtonText>{trans.proceed}</ButtonText>
              </>
            )}
          </Button>
        </ContentToRight>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
