import { Button, ContentContainer, Header, Icon } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  MicropaymentInfoContent,
  MicropaymentInfoGraph,
  MicropaymentWarn,
  MicropaymentWarnInner,
} from '../../styles/generic.styles'
import { JitpayGeneralConditionsSpacerMobile } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../../styles/layout.styles'
import { JitpayInfoGraph } from './JitpayInfoGraph'

export const MicropaymentInfoJitpayPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState

  const { micropaymentInfoJitpayPage: trans } =
    store.TranslationsState.translations

  const [mobileVersion, setMobileVersion] = useState(false)

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    } else {
      setMobileVersion(false)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <MicropaymentInfoGraph>
        <JitpayInfoGraph />
      </MicropaymentInfoGraph>

      <MicropaymentInfoContent>{trans.content}</MicropaymentInfoContent>

      <MicropaymentWarn>
        <MicropaymentWarnInner>
          <Icon
            size="24px"
            type="alert-triangle"
            color="rgba(255, 143, 143, 1)"
          />
          <span>{trans.warning}</span>
        </MicropaymentWarnInner>
      </MicropaymentWarn>

      {mobileVersion ? <JitpayGeneralConditionsSpacerMobile /> : <></>}
      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() => handleProceed()}
          {...theme.button}
          width={mobileVersion ? '100%' : '224px'}
          paddingMobile="14px 40px"
        >
          <>
            <ButtonImgWrap>
              <Icon size="18px" type="checkmark" />
            </ButtonImgWrap>
            <ButtonText>{trans.proceed}</ButtonText>
          </>
        </Button>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
