import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  WebcamPhotoCapture,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useRef, useState } from 'react'
import { StoreContext } from '../../components/App'
import { ButtonText, UploadParagraph } from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  MarginOnly,
  Margined,
  MarginedWithHeight,
} from '../../styles/layout.styles'

import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'

export const AddressUploadBackSideIkanoPage = observer(() => {
  const store = useContext(StoreContext)

  const { currentPageIndex, context } = store.ScenarioState
  const { theme } = store.InterfaceState

  const { AddressUploadBackSideIkano: trans } =
    store.TranslationsState.translations

  const webcamRef = useRef<WebcamPhotoCapture>(null)
  const [photoDataUrl, setPhotoDataUrl] = useState<string | undefined>()

  const [loading, setLoading] = useState(false)

  const handleTakePhoto = async () => {
    await webcamRef.current?.takePhoto()
  }

  const handleReset = () => {
    webcamRef.current?.reset()
    setPhotoDataUrl(undefined)
  }

  const handleProceed = async () => {
    if (!photoDataUrl) return

    setLoading(true)
    await handlePhotoUpload()
    setLoading(false)

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handlePhotoUpload = async () => {
    try {
      await axiosInstance.post(
        context.documentUploadUrl,
        { file: photoDataUrl },
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {loading ? (
        <>
          <MarginedWithHeight margin="0 0 15px" height="0">
            <Header
              {...theme.header}
              fontFamily={theme.globals.fontFamilyHeadline}
            >
              {trans.verifying}
            </Header>
          </MarginedWithHeight>
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            {...theme.loadingSpinner}
          />
        </>
      ) : (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>

          <MarginOnly margin="20px 0 20px 0">
            <UploadParagraph>{trans.description}</UploadParagraph>
          </MarginOnly>

          <WebcamPhotoCapture
            viewerWidth="100%"
            ref={webcamRef}
            selectStyles={theme.selectInput}
            showFrame
            onPhotoCapture={(value) => setPhotoDataUrl(value)}
          />

          <Margined margin="0 0 30px" />
          {/* {<HorizontalBorder margin="30px 0" />} */}

          <ContentContainerBottomFixed mobileGap="16px">
            {photoDataUrl ? (
              <>
                <Button
                  {...theme.button}
                  width="160px"
                  paddingMobile="14px 40px"
                  onClick={handleReset}
                >
                  {trans.retakePhoto}
                </Button>

                <Button
                  onClick={() => handleProceed()}
                  {...theme[!photoDataUrl ? 'buttonDisabled' : 'button']}
                  {...theme.button}
                  width="160px"
                  paddingMobile="14px 40px"
                >
                  <>
                    <ButtonText>{trans.proceed}</ButtonText>
                  </>
                </Button>
              </>
            ) : (
              <>
                <Button
                  {...theme.button}
                  width="164px"
                  paddingMobile="14px 40px"
                  onClick={handleTakePhoto}
                >
                  {trans.takePhoto}
                </Button>
              </>
            )}
          </ContentContainerBottomFixed>
        </>
      )}
    </ContentContainer>
  )
})
