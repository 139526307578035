import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
  Popup,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  Condition,
  ConditionTitle,
  RequiredFooter,
  IntroParagraph,
} from '../styles/generic.styles'
import { ContentContainerBottomFixed } from '../styles/layout.styles'
import { TickAllStyle } from '../styles/input.styles'
import privacy_policy from '../assets/privacy_policy.pdf'
import videoid_terms from '../assets/videoid_terms.pdf'
import videoId_privacy_policy from '../assets/videoId_privacy_policy.pdf'

export const GeneralConditionsBeforeBankSelectionPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth, pageHeight } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { generalConditionsPage: trans } = store.TranslationsState.translations

  // Checkboxes state
  const [terms, setTerms] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [biometrics, setBiometrics] = useState(false)
  const [tickAll, setTickAll] = useState(false)

  // Popups visibility
  const [docPopupVisible, setDocPopupVisible] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)
  const [biometricsPopupVisible, setBiometricsPopupVisible] = useState(false)

  // Loadings state
  const [btnLoading, setBtnLoading] = useState(false)

  // Mobile utilities
  const [isMobile, setIsMobile] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
      setIsMobile(true)
    }
  }, [pageWidth, pageHeight])

  const handleProceed = async () => {
    if (!(terms && policy)) return

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handleTerms = ({ checked: value }) => {
    setTerms(value)
  }

  const handlePolicy = ({ checked: value }) => {
    setPolicy(value)
  }

  const handleBiometrics = ({ checked: value }) => {
    setBiometrics(value)
  }

  const handleTickAll = () => {
    setTickAll(!tickAll)
    setBiometrics(!tickAll)
    setTerms(!tickAll)
    setPolicy(!tickAll)
  }

  const isTouchScrollDisabled = () => {
    if (docPopupVisible || privPopupVisible || biometricsPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.header}
        </Header>

        <IntroParagraph
          color={theme.globals.linkColor}
          colorHover={theme.globals.linkColorHover}
        >
          {
            'Welcome to the Signicat Identity Verification Process. Signicat, with our subsidiary ElectronicID, is a EU certified '
          }
          <a
            href="https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42"
            target="_blank"
            rel="noreferrer"
          >
            trusted service provider
          </a>
        </IntroParagraph>

        <Condition margin="30px 0 10px">
          <ConditionTitle
            required
            asteriskColor={theme.globals.inputRequired.asteriskColor}
          >
            Terms and Conditions of use
          </ConditionTitle>
          <DocumentCheckbox
            inputName="terms-checkbox"
            description="I accept the terms and conditions of the video-identification process"
            externalUrl={videoid_terms}
            handleDecision={handleTerms}
            value={terms}
            {...theme.documentCheckbox}
            margin="0 0 30px 0"
            contentFontsize="14px"
            iconSize="26px"
            onClick={() => setDocPopupVisible(true)}
          />
        </Condition>

        <>
          <span className="spacer" />
          <Condition>
            <ConditionTitle
              required
              asteriskColor={theme.globals.inputRequired.asteriskColor}
            >
              Privacy Policy
            </ConditionTitle>
            <DocumentCheckbox
              inputName="privacy-checkbox"
              description="I have read the Privacy Policy and I consent to the processing and storage of my biometric data and the complete recording of the video. Otherwise, you have other alternatives identified in the Terms and Conditions"
              externalUrl={videoId_privacy_policy}
              handleDecision={handlePolicy}
              value={policy}
              {...theme.documentCheckbox}
              margin="0 0 40px 0"
              contentFontsize="14px"
              iconSize="26px"
              onClick={() => setPrivPopupVisible(true)}
            />
          </Condition>
        </>

        <>
          <span className="spacer" />
          <Condition>
            <ConditionTitle>Collecting data during VideoID</ConditionTitle>
            <DocumentCheckbox
              inputName="biometrics-checkbox"
              description="I Consent to Signicat SLU to use my data, collected in the VideoID process, to improve their software to recognize and prevent fraud."
              externalUrl={privacy_policy}
              handleDecision={handleBiometrics}
              value={biometrics}
              {...theme.documentCheckbox}
              margin="0 0 40px 0"
              contentFontsize="14px"
              iconSize="26px"
              onClick={() => setBiometricsPopupVisible(true)}
            />
          </Condition>
        </>

        <ContentContainerBottomFixed>
          <RequiredFooter
            fontSize={theme.globals.inputRequired.textFontSize}
            color={theme.globals.inputRequired.textColor}
          >
            {trans.required}
          </RequiredFooter>

          <TickAllStyle width="100%" paddingMobile="14px 10px" padding="20px 0">
            <input
              id="tick-all"
              type="checkbox"
              name="tick-all"
              checked={tickAll}
            />
            <span className="label-text" onClick={handleTickAll}>
              {trans.tickAll}
            </span>
          </TickAllStyle>

          <Button
            onClick={handleProceed}
            disabled={!(terms && policy)}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
            id="page-submit"
          >
            {btnLoading ? (
              <LoadingSpinner
                width="16px"
                {...theme.loadingSpinner}
                padding="0 0"
                thickness={2}
              />
            ) : (
              <>
                <ButtonImgWrap>
                  <Icon size="18px" type="checkmark" />
                </ButtonImgWrap>
                <ButtonText>{trans.proceed}</ButtonText>
              </>
            )}
          </Button>
        </ContentContainerBottomFixed>
        {isMobile ? (
          <>
            <Popup
              visibility={docPopupVisible}
              handleVisibility={setDocPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={videoid_terms}
                {...theme.pdfViewer}
                canvasWidth={348}
                viewerWidth="100%"
                viewerHeight={containerPdfViewerHeight}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>
            <Popup
              visibility={privPopupVisible}
              handleVisibility={setPrivPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={videoId_privacy_policy}
                {...theme.pdfViewer}
                canvasWidth={348}
                viewerWidth="100%"
                viewerHeight={containerPdfViewerHeight}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>
            <Popup
              visibility={biometricsPopupVisible}
              handleVisibility={setBiometricsPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={privacy_policy}
                {...theme.pdfViewer}
                canvasWidth={348}
                viewerWidth="100%"
                viewerHeight={containerPdfViewerHeight}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>
          </>
        ) : (
          <>
            <Popup
              visibility={docPopupVisible}
              handleVisibility={setDocPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={videoid_terms}
                {...theme.pdfViewer}
                canvasWidth={728}
                viewerWidth="100%"
                viewerHeight={`calc(100vh - 405px)`}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>
            <Popup
              visibility={privPopupVisible}
              handleVisibility={setPrivPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={videoId_privacy_policy}
                {...theme.pdfViewer}
                canvasWidth={728}
                viewerWidth="100%"
                viewerHeight={`calc(100vh - 405px)`}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>

            <Popup
              visibility={biometricsPopupVisible}
              handleVisibility={setBiometricsPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={privacy_policy}
                {...theme.pdfViewer}
                canvasWidth={728}
                viewerWidth="100%"
                viewerHeight={`calc(100vh - 405px)`}
                initialScale={1}
                margin="30px 0 0 0"
                containerPadding="0px"
                navigationSpacerColor="none"
              />
            </Popup>
          </>
        )}
      </>
    </ContentContainer>
  )
})
