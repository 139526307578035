import { Button, Popup } from 'authflow-ui-engine'
import React, { useState } from 'react'
import { Theme } from '../../constants/themes/themeInterface'
import { Textarea } from './VideoIdAfterCancelPopup.styles'
import { actionReport } from '../../methods/actionReport'

export const VideoIdAfterCancelPopup = ({
  title,
  thankYouForYourFeedback,
  sendButtonText,
  cancelButtonText,
  visibility,
  handleVisibility,
  theme,
}: {
  title: string
  thankYouForYourFeedback: string
  sendButtonText: string
  cancelButtonText: string
  visibility: boolean
  handleVisibility: () => void
  theme: Theme
}) => {
  const [feedback, setFeedback] = useState<string>()
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false)
  const [closeOngoing, setCloseOngoing] = useState<boolean>(false)

  const handleProceed = (e) => {
    e.preventDefault()
    setCloseOngoing(true)

    try {
      actionReport({
        type: 'event.onboarding-web.eidv.CANCEL_SURVEY',
        payload: {
          feedback,
        },
      })
      setFeedbackSent(true)

      //eslint-disable-next-line
    } catch (error) {}

    setTimeout(() => {
      handleVisibility()
    }, 2000)
  }

  const handleClose = () => {
    setCloseOngoing(true)
    handleVisibility()
  }

  return (
    <Popup
      visibility={visibility}
      handleVisibility={handleClose}
      width="700px"
      {...theme.popup}
      closeIconHidden={closeOngoing}
      clickOutsideEnabled={!closeOngoing}
      zIndex="2"
      title={title}
      footer={
        <>
          <Button
            onClick={(e) => handleProceed(e)}
            disabled={!feedback || closeOngoing}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
          >
            {sendButtonText}
          </Button>
          <Button
            onClick={handleClose}
            disabled={closeOngoing}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
          >
            {cancelButtonText}
          </Button>
        </>
      }
    >
      {feedbackSent ? (
        thankYouForYourFeedback
      ) : (
        <Textarea
          name="feedback"
          rows={6}
          style={{ width: '100%' }}
          onChange={(e) => setFeedback(e.target.value)}
        />
      )}
    </Popup>
  )
}
