import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { devLog } from '../methods/devLog'
import { StoreContext } from './App'

import { scenarios } from '../constants/scenarios'

import { CompleteUploadPage } from '../pages/CompleteUploadPage'
import { ContinueOnComputerPage } from '../pages/ContinueOnComputerPage'
import { CountryPage } from '../pages/CountryPage'
import { DeviceSwitchedPage } from '../pages/DeviceSwitchedPage'
import { DocumentTypePage } from '../pages/DocumentTypePage'
import { ErrorPage } from '../pages/ErrorPage'
import { GeneralConditionsPage } from '../pages/GeneralConditionsPage'
import { IncorrectPinCodePage } from '../pages/IncorrectPinCodePage'
import { GoToMobileThankYouPage } from '../pages/GoToMobileThankYouPage'
import { InitialPage } from '../pages/InitialPage'
import { KlarnaPrivacyPolicyPage } from '../pages/KlarnaPrivacyPolicyPage'
import { MicropaymentFintecPage } from '../pages/MicropaymentFintecPage'
import { MicropaymentInfoPage } from '../pages/MicropaymentInfoPage'
import { MicropaymentKlarnaPage } from '../pages/MicropaymentKlarnaPage'
import { MocksPage } from '../pages/MocksPage'
import { NotFoundPage } from '../pages/NotFoundPage'
import { OtpPage } from '../pages/OtpPage'
import { PixelSpeakPage } from '../pages/PixelSpeakPage'
import { ProgressPage } from '../pages/ProgressPage'
import { SignDocumentPage } from '../pages/SignDocumentPage'
import { UserDataPage } from '../pages/UserDataPage'
import { VideoIdPage } from '../pages/VideoIdPage'
import { VideoOnboardingPage } from '../pages/VideoOnboardingPage'
import { AisKlarnaPage } from '../pages/dtp/AisKlarnaPage'
import { DTPWaitingPage } from '../pages/dtp/DTPWaiting'
import { DTPInfoPage } from '../pages/dtp/DTPInfoPage'
import { TermsAndConditionsDTPInfoPage } from '../pages/dtp/TermsAndConditionsDTPInfoPage'
import { VerifyingPage } from '../pages/VerifyingPage'
import { AccountHolderMockIkanoPage } from '../pages/ikano/AccountHolderMockIkanoPage'
import { AddressUploadBackSideIkanoPage } from '../pages/ikano/AddressUploadBackSideIkanoPage'
import { AddressUploadBothSidesIkanoPage } from '../pages/ikano/AddressUploadBothSidesIkanoPage'
import { AddressUploadFrontSideIkanoPage } from '../pages/ikano/AddressUploadFrontSideIkanoPage'
import { AddressUploadIkanoPage } from '../pages/ikano/AddressUploadIkanoPage'
import { AddressVerificationIkanoPage } from '../pages/ikano/AddressVerificationIkanoPage'
import { AddressVerificationTwoSidedIkanoPage } from '../pages/ikano/AddressVerificationTwoSidedIkanoPage'
import { CompleteIkanoPage } from '../pages/ikano/CompleteIkanoPage'
import { ContinueOnMobilePage } from '../pages/ContinueOnMobilePage'
import { DocumentTypeIkanoPage } from '../pages/ikano/DocumentTypeIkanoPage'
import { GeneralConditionsIkanoPage } from '../pages/ikano/GeneralConditionsIkanoPage'
import { GeneralConditionsWithMicropaymentIkanoPage } from '../pages/ikano/GeneralConditionsWithMicropaymentIkanoPage'
import { TermsAndConditionsWithMicropaymentIkanoPage } from '../pages/ikano/TermsAndConditionsWithMicropaymentIkanoPage'
import { MicropaymentFintecIkanoPage } from '../pages/ikano/MicropaymentFintecIkanoPage'
import { MicropaymentInfoIkanoPage } from '../pages/ikano/MicropaymentInfoIkanoPage'
import { MicropaymentInfoIkanoOpenBankingPage } from '../pages/ikano/MicropaymentInfoIkanoOpenBankingPage'
import { TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage } from '../pages/ikano/TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage'
import { MicropaymentInfoWithVideoIdIkanoPage } from '../pages/ikano/MicropaymentInfoWithVideoIdIkanoPage'
import { MicropaymentReversedInfoWithVideoIdIkanoPage } from '../pages/ikano/MicropaymentReversedInfoWithVideoIdIkanoPage'
import { SignDocumentIkanoPage } from '../pages/ikano/SignDocumentIkanoPage'
import { SwitchToMobilePage } from '../pages/SwitchToMobilePage'
import { ResumeFromEmailPage } from '../pages/ResumeFromEmailPage'
import { VideoIdIkanoPage } from '../pages/ikano/VideoIdIkanoPage'
import { VideoIdIkanoPageStandalone } from '../pages/ikano/VideoIdIkanoPageStandalone'
import { GeneralConditionsJitpay } from '../pages/jitpay/GeneralConditionsJitpay'
import { GeneralConditionsJitpayQES } from '../pages/jitpay/GeneralConditionsJitpayQES'
import { TermsAndConditionsJitpayPage } from '../pages/jitpay/TermsAndConditionsJitpayPage'
import { MicropaymentInfoJitpayPage } from '../pages/jitpay/MicropaymentInfoJitpayPage'
import { MicropaymentInfoJitpayPageStandalone } from '../pages/jitpay/MicropaymentInfoJitpayPageStandalone'
import { MicropaymentKlarnaPageJitpay } from '../pages/jitpay/MicropaymentKlarnaPageJitpay'
import { MicropaymentKlarnaPageJitpayStandalone } from '../pages/jitpay/MicropaymentKlarnaPageJitpayStandalone'
import { ProgressPageJitpay } from '../pages/jitpay/ProgressPageJitpay'
import { VideoIdPageJitpay } from '../pages/jitpay/VideoIdPageJitpay'
import { VideoOnboardingJitPayPage } from '../pages/jitpay/VideoOnboardingJitPayPage'
import { AddressUploadPageKontomatik } from '../pages/kontomatik/AddressUploadPageKontomatik'
import { IntroKontomatikPage } from '../pages/kontomatik/IntroKontomatikPage'
import { KontomatikPage } from '../pages/kontomatik/KontomatikPage'
import { ProgressPageKontomatik } from '../pages/kontomatik/ProgressPageKontomatik'
import { rootStore } from '../store/Root.store'
import { ContentWrap, ContenRoutertWrap } from '../styles/layout.styles'
import { SignicatBranding } from './SignicatBranding/SignicatBranding'
import { TopBar } from './TopBar'
import { BankIdRetryPage } from '../pages/BankIdRetryPage'
import { BankSelectionPage } from '../pages/BankSelectionPage'
import { GeneralConditionsBeforeBankSelectionPage } from '../pages/GeneralConditionsBeforeBankSelectionPage'
import { TermsAndConditionsPage } from '../pages/TermsAndConditionsPage'
import { SaltedgeRedirectPage } from '../pages/SaltedgeRedirectPage'

const PAGES = {
  GeneralConditions: GeneralConditionsPage,
  SignDocument: SignDocumentPage,
  KlarnaPrivacyPolicy: KlarnaPrivacyPolicyPage,
  VideoId: VideoIdPage,
  Otp: OtpPage,
  DocumentType: DocumentTypePage,
  Country: CountryPage,
  UserData: UserDataPage,
  VideoOnboarding: VideoOnboardingPage,
  MicropaymentFintec: MicropaymentFintecPage,
  MicropaymentFintecIkano: MicropaymentFintecIkanoPage,
  MicropaymentKlarna: MicropaymentKlarnaPage,
  Mocks: MocksPage,
  Initial: InitialPage,
  Progress: ProgressPage,
  IntroKontomatik: IntroKontomatikPage,
  KontomatikPage: KontomatikPage,
  ProgressPageKontomatik: ProgressPageKontomatik,
  AddressUploadPageKontomatik: AddressUploadPageKontomatik,
  GeneralConditionsJitpay: GeneralConditionsJitpay,
  GeneralConditionsJitpayQES: GeneralConditionsJitpayQES,
  TermsAndConditionsJitpay: TermsAndConditionsJitpayPage,
  VideoIdJitpay: VideoIdPageJitpay,
  MicropaymentKlarnaJitpay: MicropaymentKlarnaPageJitpay,
  ProgressPageJitpay: ProgressPageJitpay,
  VideoOnboardingJitpay: VideoOnboardingJitPayPage,
  MicropaymentInfoJitpay: MicropaymentInfoJitpayPage,
  MicropaymentKlarnaJitpayStandalone: MicropaymentKlarnaPageJitpayStandalone,
  MicropaymentInfoJitpayStandalone: MicropaymentInfoJitpayPageStandalone,
  GeneralConditionsIkano: GeneralConditionsIkanoPage,
  MicropaymentInfoIkano: MicropaymentInfoIkanoPage,
  MicropaymentInfoIkanoOpenBanking: MicropaymentInfoIkanoOpenBankingPage,
  TermsAndConditionsMicropaymentInfoIkanoOpenBanking:
    TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage,
  GeneralConditionsWithMicropaymentIkano:
    GeneralConditionsWithMicropaymentIkanoPage,
  TermsAndConditionsWithMicropaymentIkano:
    TermsAndConditionsWithMicropaymentIkanoPage,
  MicropaymentInfoWithVideoIdIkano: MicropaymentInfoWithVideoIdIkanoPage,
  MicropaymentReversedInfoWithVideoIdIkano:
    MicropaymentReversedInfoWithVideoIdIkanoPage,
  VideoIdIkanoPageStandalone: VideoIdIkanoPageStandalone,
  VideoIdIkano: VideoIdIkanoPage,
  CompleteIkano: CompleteIkanoPage,
  SignDocumentIkano: SignDocumentIkanoPage,
  PixelSpeakPage: PixelSpeakPage,
  DocumentTypeIkano: DocumentTypeIkanoPage,
  CompleteUpload: CompleteUploadPage,
  AddressVerificationIkano: AddressVerificationIkanoPage,
  AddressUploadIkano: AddressUploadIkanoPage,
  AddressVerificationTwoSidedIkano: AddressVerificationTwoSidedIkanoPage,
  AddressUploadFrontSideIkano: AddressUploadFrontSideIkanoPage,
  AddressUploadBackSideIkano: AddressUploadBackSideIkanoPage,
  AccountHolderMockIkano: AccountHolderMockIkanoPage,
  ErrorPage: ErrorPage,
  AddressUploadBothSidesIkano: AddressUploadBothSidesIkanoPage,
  AisKlarna: AisKlarnaPage,
  SwitchToMobile: SwitchToMobilePage,
  ContinueOnMobile: ContinueOnMobilePage,
  ResumeFromEmail: ResumeFromEmailPage,
  DTPWaiting: DTPWaitingPage,
  DTPInfo: DTPInfoPage,
  TermsAndConditionsDTPInfo: TermsAndConditionsDTPInfoPage,
  Verifying: VerifyingPage,
  IncorrectPinCode: IncorrectPinCodePage,
  GoToMobileThankYou: GoToMobileThankYouPage,
  DeviceSwitched: DeviceSwitchedPage,
  ContinueOnComputer: ContinueOnComputerPage,
  MicropaymentInfo: MicropaymentInfoPage,
  BankIdRetry: BankIdRetryPage,
  BankSelection: BankSelectionPage,
  GeneralConditionsBeforeBankSelection:
    GeneralConditionsBeforeBankSelectionPage,
  TermsAndConditions: TermsAndConditionsPage,
  SaltedgeRedirect: SaltedgeRedirectPage,
}

const PAGES_WITHOUT_KEEP_ALIVE = [
  'VideoId',
  'MicropaymentFintec',
  'MicropaymentKlarna',
  'MicropaymentKlarnaJitpay',
  'MicropaymentKlarnaJitpayStandalone',
  'AisKlarna',
  'VideoIdJitpay',
  'AddressUploadPageKontomatik',
  'Otp',
  'CompleteIkano',
  'VideoIdIkano',
  'CompleteUpload',
  'ErrorPage',
  'Mocks',
  'IncorrectPinCode',
  'GoToMobileThankYou',
  'DeviceSwitched',
  'ContinueOnComputer',
  'ContinueOnMobile',
]

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { currentScenarioId, currentPageIndex } = store.ScenarioState
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState

  useEffect(() => {
    devLog(currentScenarioId)
  }, [currentScenarioId])

  const currentStep = scenarios?.[currentScenarioId]?.[currentPageIndex]
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [pageWidth])

  const renderScenarioPage = () => {
    const path = window.location.pathname.split('/')[1]
    const errorPaths = ['error', 'error-session-expired', 'error-already-used']

    if (errorPaths.some((v) => path.includes(v))) {
      return <ErrorPage />
    }

    const Handler =
      PAGES[scenarios[currentScenarioId][currentPageIndex]] || NotFoundPage

    if (PAGES_WITHOUT_KEEP_ALIVE.includes(currentStep)) {
      store.TrackingState.setShouldKeepAlive(false)
    } else {
      store.TrackingState.setShouldKeepAlive(true)
    }

    devLog(
      'Currently rendered page: ',
      scenarios[currentScenarioId][currentPageIndex]
    )

    return <Handler />
  }

  const scenariosWithNoDefaultBrandingPlacements = ['error']

  const stepsWithNoDefaultBrandingPlacements = [
    'MicropaymentKlarnaJitpay',
    'MicropaymentKlarna',
    'MicropaymentKlarnaJitpayStandalone',
  ]

  const stepsWith1000pxBranding = [
    'VideoIdJitpay',
    'VideoId',
    'VideoIdIkano',
    'SignDocument',
    'SignDocumentIkano',
  ]

  const getBranding = () => {
    if (
      scenariosWithNoDefaultBrandingPlacements.includes(currentScenarioId) ||
      stepsWithNoDefaultBrandingPlacements.includes(currentStep)
    ) {
      return <></>
    }

    if (stepsWith1000pxBranding.includes(currentStep)) {
      // branding hidden for all steps until we find a solution
      return <SignicatBranding width="1000px" hideOnMobile />
    }

    // branding hidden for all steps until we find a solution
    return <SignicatBranding width="560px" hideOnMobile />
  }

  return (
    <ContenRoutertWrap
      className="content-router"
      showBackgroundImage={!isMobile}
      {...theme.contentWrap}
    >
      <TopBar />
      <ContentWrap
        color={theme.globals.linkColor}
        fontFamily={theme.globals.fontFamily}
        fontFamilyHeadline={theme.globals.fontFamilyHeadline}
        iframeContent={rootStore.AppState.iframeContent}
        bgColor={theme.globals.bgColor}
      >
        {renderScenarioPage()}
        {getBranding()}
      </ContentWrap>
    </ContenRoutertWrap>
  )
})
