import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { StoreContext } from '../../components/App'
import {
  ButtonText,
  MicropaymentInfoContent,
  MicropaymentInfoContentWithBorder,
} from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../../styles/layout.styles'
import { MicropaymentNamensabgleich } from '../../styles/ikano.styles'
import { IkanoNamensabgleich } from './IkanoNamensabgleich'
import { actionReport } from '../../methods/actionReport'

const apiUrl = process.env.WEB_API_URL

export const MicropaymentInfoWithVideoIdIkanoPage = observer((props) => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState
  const { micropaymentInfoWithVideoIdIkanoPage: trans } =
    store.TranslationsState.translations

  const [mobileVersion, setMobileVersion] = useState(false)

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
  })

  const getUserData = async () => {
    setLoading(true)
    const { data } = await axios.get<{
      firstName: string
      lastName: string
    }>(`${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/user-data`, {
      withCredentials: true,
    })
    setUser(data)
    setLoading(false)
  }

  const handleProceed = async () => {
    actionReport({
      type: 'event.onboarding-web.tink.PROCEED_CTA_CLICKED',
      payload: {
        variant: props.variantName ?? '',
      },
    })

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {loading ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>

          <MicropaymentNamensabgleich>
            <IkanoNamensabgleich />
          </MicropaymentNamensabgleich>

          <MicropaymentInfoContent>
            {trans.contentBPart1} <b>{trans.contentBPart2}</b>{' '}
            {trans.contentBPart3} <b>{trans.contentBPart4}</b>
          </MicropaymentInfoContent>

          <MicropaymentInfoContent>
            {trans.yourName || 'Dein Name auf dem Antrag ist:'} <br />
            <b>
              {user.firstName} {user.lastName}
            </b>
          </MicropaymentInfoContent>

          <MicropaymentInfoContent>
            {trans.contentBPart5} <b>{trans.contentBPart6}</b>{' '}
            {trans.contentBPart7}
          </MicropaymentInfoContent>

          <MicropaymentInfoContentWithBorder>
            <b>{trans.contentBLogPart1}</b> {trans.contentBLogPart2}
          </MicropaymentInfoContentWithBorder>

          <ContentContainerBottomFixed>
            {!mobileVersion ? <ContentContainerBottomLeft /> : <div />}

            <Button
              onClick={() => handleProceed()}
              {...theme.button}
              width="164px"
              paddingMobile="14px 40px"
            >
              <>
                <ButtonText>{trans.continue}</ButtonText>
              </>
            </Button>
          </ContentContainerBottomFixed>
        </>
      )}
    </ContentContainer>
  )
})
