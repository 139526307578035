import React, { useContext, useState } from 'react'
import axios from 'axios'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { ButtonText, IntroParagraph } from '../../../styles/generic.styles'
import { Header, OtpInput, Button, LoadingSpinner } from 'authflow-ui-engine'
import {
  ContentContainerBottomFixed,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '../../../styles/layout.styles'
import { CodeInputWrapper } from '../ResumeFromEmail.styles'
import { forceRedirect } from '../../../methods/forceRedirect'
import { devLog } from '../../../methods/devLog'

interface OtpProps {
  nextStep: () => void
  maxAttempts: number
  verifyOtpUrl: string
}

export const Otp = observer(
  ({ nextStep, maxAttempts, verifyOtpUrl }: OtpProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { ResumeFromEmailPage: trans } = store.TranslationsState.translations

    const [isCodeBeingChecked, setIsCodeBeingChecked] = useState(false)
    const [isBeingRedirected, setIsBeingRedirected] = useState(false)
    const [isCodeIncorrect, setIsCodeIncorrect] = useState(false)
    const [attemptsLeft, setAttemptsLeft] = useState(maxAttempts)

    const [otp, setOtp] = useState('')

    const handleOtpSubmit = async () => {
      setIsCodeBeingChecked(true)
      try {
        const { data } = await axios.post(
          verifyOtpUrl,
          { otp: otp },
          { withCredentials: true }
        )

        if (data?.proceedUrl) {
          setIsBeingRedirected(true)
          forceRedirect(data.proceedUrl)
        } else if (data?.otpAttemptsLeft > 0) {
          setIsCodeIncorrect(true)
          setOtp('')
          setAttemptsLeft(data.otpAttemptsLeft)
        } else {
          setIsBeingRedirected(true)
          nextStep()
        }
      } catch (error) {
        devLog(error)
      }
      setIsCodeBeingChecked(false)
    }

    return (
      <>
        <Header
          {...theme.header}
          fontFamily={theme.globals.fontFamilyHeadline}
          margin="30px 0"
        >
          {trans.OtpPage.header}
        </Header>

        <IntroParagraph textAlign="center">
          {trans.OtpPage.description}
        </IntroParagraph>

        <Margined margin="0 0 30px" />

        <CodeInputWrapper
          isLoading={isCodeBeingChecked}
          isError={isCodeIncorrect}
        >
          <OtpInput
            {...theme.otpInput}
            value={otp}
            valueLength={6}
            onChange={(val) => {
              setOtp(val)
              setIsCodeIncorrect(false)
            }}
            marginTop="40px"
            marginBottom="40px"
            inputHeight="68px"
          />

          {isCodeIncorrect && (
            <IntroParagraph> {trans.OtpPage.error1}</IntroParagraph>
          )}

          {attemptsLeft < maxAttempts && attemptsLeft > 1 && (
            <span>
              {trans.OtpPage.attemptsLeft.replace(
                '<number>',
                attemptsLeft.toString()
              )}
            </span>
          )}

          {attemptsLeft === 1 && <span>{trans.OtpPage.lastAttempt}</span>}
        </CodeInputWrapper>

        <ContentContainerBottomFixed borderTop="none">
          {!isCodeBeingChecked && !isBeingRedirected ? (
            <Button
              disabled={isCodeBeingChecked || otp.length < 6}
              onClick={handleOtpSubmit}
              {...theme.button}
              width="190px"
              paddingMobile="14px 10px"
              margin="0 0 20px 0"
            >
              <ButtonText>{trans.OtpPage.continue}</ButtonText>
            </Button>
          ) : (
            <LoadingSpinner
              width="40px"
              bgColor="blue"
              padding="100px 0 40px 0"
              height="0"
              {...theme.loadingSpinner}
            />
          )}
        </ContentContainerBottomFixed>

        <MobileSpacerForContentContainerBottomFixed />
      </>
    )
  }
)
