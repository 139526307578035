import { ContentContainer, Header } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'

import { IntroParagraph } from '../styles/generic.styles'
import { HorizontalIconContainer } from '../styles/ikano.styles'
import { CenteredContentColumn, Margined } from '../styles/layout.styles'

export const DeviceSwitchedPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { SwitchToMobile: trans } = store.TranslationsState.translations

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.close.header}
      </Header>

      <CenteredContentColumn>
        <IntroParagraph>{trans.close.description}</IntroParagraph>

        <Margined margin="0 0 30px" />

        <HorizontalIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="130"
            viewBox="0 0 70 130"
            fill="none"
          >
            <path
              d="M59.99 2.2832C64.5071 2.2832 68.1689 5.94503 68.1689 10.4621L68.1689 120.06C68.1689 124.577 64.5071 128.238 59.99 128.238L10.0114 128.238C5.49433 128.238 1.8325 124.577 1.8325 120.06L1.8325 10.4621C1.8325 5.94503 5.49433 2.2832 10.0114 2.2832L59.99 2.2832Z"
              fill="white"
              stroke="#DCDBE2"
              strokeWidth="3"
            />
            <path
              d="M53.0537 76.1768H63.9698V96.3296H53.0537V76.1768Z"
              fill="#FCFBFD"
            />
            <path
              d="M16.9463 120.682L6.03017 120.682L6.03017 100.529L16.9463 100.529L16.9463 120.682Z"
              fill="#FCFBFD"
            />
            <path
              d="M6.03027 19.0771H32.9007V55.1843H6.03027V19.0771Z"
              fill="#F6F5FA"
            />
            <path
              d="M6.03027 76.1768H49.6948V96.3296H6.03027V76.1768Z"
              fill="#FCFBFD"
            />
            <path
              d="M63.9707 120.681L20.3062 120.681L20.3062 100.528L63.9707 100.528L63.9707 120.681Z"
              fill="#F6F5FA"
            />
            <path
              d="M37.0996 19.0771H63.9701V55.1843H37.0996V19.0771Z"
              fill="#FCFBFD"
            />
            <path
              d="M3.51855 10.2607C3.51855 6.39475 6.65256 3.26074 10.5186 3.26074H59.5186C63.3845 3.26074 66.5186 6.39475 66.5186 10.2607V15.2607H3.51855V10.2607Z"
              fill="#FCFBFD"
            />
            <rect
              x="25.3428"
              y="7.32129"
              width="19.3131"
              height="5.87791"
              rx="2.93896"
              fill="#DCDBE2"
            />
          </svg>
        </HorizontalIconContainer>
      </CenteredContentColumn>
    </ContentContainer>
  )
})
