import { ContentContainer, LoadingSpinner } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'

export const InitialPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </ContentContainer>
  )
})
