import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  LoadingSpinner,
  PdfViewer,
  Popup,
} from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import {
  ButtonText,
  ConditionTitle,
  IntroParagraph,
  RequiredFooter,
} from '../../styles/generic.styles'
import { IkanoStyleWrap, LineHeight } from '../../styles/ikano.styles'
import { Divider, WelcomeCheck } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'
import { TickAllStyle } from '../../styles/input.styles'
import {
  AcceptanceDocument,
  AcceptanceDocumentRes,
} from '../../types/acceptanceDocument'

export const GeneralConditionsIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState

  const { pageWidth, pageHeight } = store.AppState
  const { ikanoWelcomePage: trans, generalConditionsPage: transGeneral } =
    store.TranslationsState.translations

  // Checkboxes state
  const [terms, setTerms] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [biometrics, setBiometrics] = useState(false)
  const [signed, setSigned] = useState(false)
  const [tickAll, setTickAll] = useState(false)

  // Popups visibility
  const [docPopupVisible, setDocPopupVisible] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)
  const [biometricsPopupVisible, setBiometricsPopupVisible] = useState(false)

  // Documents fetched objects
  const [termsDoc, setTermsDoc] = useState<AcceptanceDocument>()
  const [policyDoc, setPolicyDoc] = useState<AcceptanceDocument>()
  const [biometricsDoc, setBiometricsDoc] = useState<AcceptanceDocument>()

  // Loadings state
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  // Mobile utilities
  const [isMobile, setIsMobile] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
      setIsMobile(true)
    }
  }, [pageWidth, pageHeight])

  const getDocuments = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get<AcceptanceDocumentRes>(
        `${apiUrl}/electronic-id/acceptance-document`,
        { withCredentials: true }
      )

      const privacyPolicy = res.data.find(
        (item) => item.type === 'PRIVACY_POLICY'
      )
      const termsAndConditions = res.data.find(
        (item) => item.type === 'TERMS_AND_CONDITIONS'
      )
      const biometrics = res.data.find(
        (item) => item.type === 'BIOMETRIC_CONSENT'
      )

      setTermsDoc(termsAndConditions)
      setPolicyDoc(privacyPolicy)
      setBiometricsDoc(biometrics)

      setLoading(false)
    } catch (e) {
      devLog(e)
    }
  }

  const handleTerms = ({ checked: value }) => {
    actionReport({
      type: 'event.onboarding-web.consent.TERMS_AND_CONDITIONS_CHECKED',
      payload: {},
    })
    setTerms(value)
  }

  const handlePrivacyPolicy = ({ checked: value }) => {
    actionReport({
      type: 'event.onboarding-web.consent.PRIVACY_POLICY_CHECKED',
      payload: {},
    })
    setPrivacyPolicy(value)
  }

  const handleBiometrics = ({ checked: value }) => {
    setBiometrics(value)
    actionReport({
      type: 'event.onboarding-web.consent.BIOMETRIC_CONSENT_CHECKED',
      payload: {},
    })
  }

  const handleTickAll = () => {
    setTickAll(!tickAll)
    setPrivacyPolicy(!tickAll)
    setBiometrics(!tickAll)
    setTerms(!tickAll)
  }

  const handleProceed = async () => {
    if (!signed) return

    setBtnLoading(true)

    try {
      const apiUrl = process.env.WEB_API_URL

      await axiosInstance.post(
        `${apiUrl}/electronic-id/acceptance-document`,
        {
          acceptedDocuments: biometrics
            ? [policyDoc?.id, termsDoc?.id, biometricsDoc?.id]
            : [policyDoc?.id, termsDoc?.id],
        },
        {
          withCredentials: true,
        }
      )
    } catch (error) {
      devLog(error)
    }

    setBtnLoading(false)
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    setSigned(terms === true && privacyPolicy === true)
  }, [terms, privacyPolicy])

  useEffect(() => {
    if (window.location.pathname.split('/')[1] !== 'mock') {
      getDocuments()
    }
  }, [])

  const isTouchScrollDisabled = () => {
    if (docPopupVisible || privPopupVisible || biometricsPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  useEffect(() => {
    if (docPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.TERMS_AND_CONDITIONS_DISPLAYED',
        payload: {},
      })
    }
  }, [docPopupVisible])

  useEffect(() => {
    if (privPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.PRIVACY_POLICY_DISPLAYED',
        payload: {},
      })
    }
  }, [privPopupVisible])

  useEffect(() => {
    if (biometricsPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.BIOMETRIC_CONSENT_DISPLAYED',
        payload: {},
      })
    }
  }, [biometricsPopupVisible])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <IkanoStyleWrap>
        {loading ? (
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            height="68px"
            {...theme.loadingSpinner}
          />
        ) : (
          <>
            <Header
              {...theme.header}
              fontFamily={theme.globals.fontFamilyHeadline}
            >
              {trans.heading}
            </Header>
            <IntroParagraph
              color={theme.globals.linkColor}
              colorHover={theme.globals.linkColorHover}
            >
              {trans.descriptionPartOne}
              <a
                href="https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42"
                target="_blank"
                rel="noreferrer"
              >
                {trans.serviceProvider}
              </a>
            </IntroParagraph>

            <Divider margin="30px 0" />

            {policyDoc?.id && (
              <WelcomeCheck>
                <LineHeight height="141.99%">
                  {/* <ConditionTitle required asteriskColor={theme.globals.inputRequired.asteriskColor}>{policyDoc?.title}</ConditionTitle> */}
                  <DocumentCheckbox
                    inputName="privacy-policy"
                    description={policyDoc?.agreement}
                    externalUrl={policyDoc?.s3Url}
                    handleDecision={handlePrivacyPolicy}
                    value={privacyPolicy}
                    {...theme.documentCheckbox}
                    margin="0 0 30px 0"
                    contentFontsize="14px"
                    iconSize="20px"
                    onClick={() => setPrivPopupVisible(true)}
                  />
                </LineHeight>
              </WelcomeCheck>
            )}
            {termsDoc?.id && (
              <WelcomeCheck margin="0 0 10px 0">
                <LineHeight height="141.99%">
                  {/* <ConditionTitle required asteriskColor={theme.globals.inputRequired.asteriskColor}>{termsDoc?.title}</ConditionTitle> */}
                  <DocumentCheckbox
                    inputName="terms-checkbox"
                    description={termsDoc?.agreement}
                    externalUrl={termsDoc?.s3Url}
                    handleDecision={handleTerms}
                    value={terms}
                    {...theme.documentCheckbox}
                    margin="0 0 30px 0"
                    contentFontsize="14px"
                    iconSize="20px"
                    onClick={() => setDocPopupVisible(true)}
                  />
                </LineHeight>
              </WelcomeCheck>
            )}
            {biometricsDoc?.id && (
              <WelcomeCheck margin="0 0 10px 0">
                <LineHeight height="141.99%">
                  {/* <ConditionTitle>{biometricsDoc?.title}</ConditionTitle> */}
                  <DocumentCheckbox
                    inputName="biometrics-checkbox"
                    description={biometricsDoc?.agreement}
                    externalUrl={biometricsDoc?.s3Url}
                    handleDecision={handleBiometrics}
                    value={biometrics}
                    {...theme.documentCheckbox}
                    margin="0 0 40px 0"
                    contentFontsize="14px"
                    iconSize="20px"
                    onClick={() => setBiometricsPopupVisible(true)}
                  />
                </LineHeight>
              </WelcomeCheck>
            )}

            <ContentContainerBottomFixed>
              {!isMobile ? <ContentContainerBottomLeft /> : <div />}

              {/* <RequiredFooter             fontSize={theme.globals.inputRequired.textFontSize}
            color={theme.globals.inputRequired.textColor}>{transGeneral.required}</RequiredFooter> */}

              {/* <TickAllStyle minWidth="224px" paddingMobile="14px 10px">
                <input
                  id="tick-all"
                  type="checkbox"
                  name="tick-all"
                  checked={tickAll}
                />
                <span className="label-text" onClick={handleTickAll}>
                  {transGeneral.tickAll}
                </span>
              </TickAllStyle> */}

              <Button
                id="btn-proceed"
                onClick={handleProceed}
                {...theme.button}
                disabled={!signed}
                minWidth="224px"
                paddingMobile="14px 40px"
              >
                {btnLoading ? (
                  <LoadingSpinner
                    width="16px"
                    {...theme.loadingSpinner}
                    padding="0 0"
                    thickness={2}
                  />
                ) : (
                  <>
                    <ButtonText>{trans.proceed}</ButtonText>
                  </>
                )}
              </Button>
            </ContentContainerBottomFixed>

            <MobileSpacerForContentContainerBottomFixed />

            {isMobile ? (
              <>
                <Popup
                  visibility={docPopupVisible}
                  handleVisibility={setDocPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={termsDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={348}
                    viewerWidth="100%"
                    viewerHeight={containerPdfViewerHeight}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
                <Popup
                  visibility={privPopupVisible}
                  handleVisibility={setPrivPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={policyDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={348}
                    viewerWidth="100%"
                    viewerHeight={containerPdfViewerHeight}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
                <Popup
                  visibility={biometricsPopupVisible}
                  handleVisibility={setBiometricsPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={biometricsDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={348}
                    viewerWidth="100%"
                    viewerHeight={containerPdfViewerHeight}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
              </>
            ) : (
              <>
                <Popup
                  visibility={docPopupVisible}
                  handleVisibility={setDocPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={termsDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={728}
                    viewerWidth="100%"
                    viewerHeight={`calc(100vh - 405px)`}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
                <Popup
                  visibility={privPopupVisible}
                  handleVisibility={setPrivPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={policyDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={728}
                    viewerWidth="100%"
                    viewerHeight={`calc(100vh - 405px)`}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
                <Popup
                  visibility={biometricsPopupVisible}
                  handleVisibility={setBiometricsPopupVisible}
                  {...theme.popup}
                >
                  <PdfViewer
                    file={biometricsDoc?.s3Url}
                    {...theme.pdfViewer}
                    canvasWidth={728}
                    viewerWidth="100%"
                    viewerHeight={`calc(100vh - 405px)`}
                    initialScale={1}
                    margin="30px 0 0 0"
                    containerPadding="0px"
                    navigationSpacerColor="none"
                  />
                </Popup>
              </>
            )}
          </>
        )}
      </IkanoStyleWrap>
    </ContentContainer>
  )
})
